
/*
 * @Author: ken yang
 * @Date: 2023-06-05 14:32:59
 * @LastEditTime: 2023-06-05 14:59:38
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/view/c2c/index.js
 */
import AppLayout from '@/layout/AppLayout.vue'
const agentApplication = () => import('@/view/agent/agentApplication/agentApplication.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/agentApplication',
        component: agentApplication,
        meta: { key: 'agentApplication' },
      },
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
