<template>
  <span class="Num num-f">
    {{ $f.numFormat(slots.default()[0].children) }}
  </span>
</template>

<script setup>

const props = defineProps({

})
const slots = useSlots()

onBeforeMount(() => {
})
</script>

<style lang="scss">
.Num {
}
</style>
