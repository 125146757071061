<!--
 * @Author: ken yang
 * @Date: 2023-06-26 16:12:08
 * @LastEditTime: 2023-07-03 14:38:57
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/components/dialog/DiaWarn.vue
-->
<template>
  <el-dialog
    v-model="detail.show"
    :width="560"
    append-to-body
    :close-on-click-modal="false"
    @closed="onClose"
  >
    <Row class="w-100b -m-t-10" x="c">
      <IconSvg name="icon-C2C_icon_warming" class="font-54" />
    </Row>
    <Row class="font-22 w-100b text-center m-t-18 p-lr-20" x="c">
      {{ data }}
    </Row>
    <Row v-if="props.subTitle" class="c-info font-14 m-t-10" x="c">{{ props.subTitle }}</Row>
    <Row x="sb" class="p-lr-20 m-t-28 m-b-10">
      <el-button class="w-220 font-18 font-w-400" @click="onLeft">{{ props.leftText||$t('alert.confirm') }}</el-button>
      <el-button class="w-220 font-18 font-w-400" type="primary" @click="onRight">{{ props.rightText||$t('alert.cancel') }}</el-button>
    </Row>
  </el-dialog>
</template>
<script setup>

const props = defineProps({
  close: {},
  onLeft: {},
  onRight: {},
  data: {
    default: ''
  },
  subTitle: {},
  leftText: {},
  rightText: {}
})
const detail = reactive({
  show: true
})
const onClose = () => {
  props.close()
}
const onLeft = () => {
  props.onLeft()
  props.close()
}
const onRight = () => {
  props.onRight()
  props.close()
}
</script>
<style lang="scss" scoped>
</style>
