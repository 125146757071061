/*
 * @Author: ken yang
 * @Date: 2023-03-07 15:20:41
 * @LastEditTime: 2023-03-07 15:21:07
 * @LastEditors: ken yang
 * @Description: 修改url参数但是不是刷新页面
 * @FilePath: /uexch_h5/src/utils/globalFunc/replaceParamVal.js
 */
const replaceParamVal = (key, value) => {
  const oUrl = window.location.href.toString()
  const re = eval('/(' + key + '=)([^&]*)/gi')
  const nUrl = oUrl.replace(re, key + '=' + value)
  window.history.pushState({}, 0, nUrl)
}

export default replaceParamVal
