/*
 * @Author: ken yang
 * @Date: 2022-09-01 15:46:32
 * @LastEditTime: 2022-09-01 16:55:02
 * @LastEditors: ken yang
 * @Description: 全局指令的入口文件
 * @FilePath: /uexch_pc/src/utils/directive/index.js
 */
import hideText from './hideText.js'
import inModuleDirective from './inModule.js'
import to from './to.js'
import openWin from './openWin.js'

export default {
  install (app) {
    app.directive('hide', hideText)
    app.directive('inmodule', inModuleDirective)
    app.directive('to', to)
    app.directive('openWin', openWin)
  }
}
