/*
 * @Author: ken yang
 * @Date: 2022-08-30 11:14:14
 * @LastEditTime: 2023-06-14 15:57:20
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/store/c2c.js
 */
import { defineStore } from 'pinia'

const useC2cStore = defineStore('c2cStore', {
  state: () => {
    return {
      _coins: [],
      _coinInfos: [],
      _fiatCurrencys: [],
      _otcFiatCoinList: [],
    }
  },
  getters: {
    coins: (state) => {
      return state._coins.map(e => ({ label: e.coinName, value: e.coinId }))
    },
    coinNames: (state) => {
      return state._coins.map(e => ({ label: e.coinName, value: e.coinName }))
    },
    coinInfos: (state) => {
      return state._coins.map(e => ({ label: e.coinName, value: e.coinId, ...e }))
    },
    fiatCurrencys: (state) => {
      return state._fiatCurrencys.map(e => ({ label: e.name, value: e.id, ...e }))
    },
    otcFiatCoinList: (state) => {
      return state._otcFiatCoinList
    },
    advertTypeOptions: (state) => {
      return [
        { label: $t('c2c.advertTypeMap.2'), value: 2 },
        { label: $t('c2c.advertTypeMap.1'), value: 1 },
      ]
    },
    payOptions: (state) => {
      return [
        { label: $t('public.bankcard'), value: 1 },
        { label: $t('public.paypal'), value: 2 },
        { label: $t('public.weChat'), value: 3 },
      ]
    },
  },
  actions: {
    async getCoins () {
      const data = await c2cApi.coins({ isOtc: 1 })
      this._coins = data
    },
    // async getCoinInfos (state) {
    //   const data = await c2cApi.coinInfoList({ isOtc: 1 })
    //   this._coinInfos = data
    //   return this._coinInfos
    // },
    async getFiatCurrencys () {
      const data = await c2cApi.fiatCurrencyList({
        pageNum: 1,
        pageSize: 1000,
      })
      this._fiatCurrencys = data.records.map(e => {
        return {
          ...e,
        }
      })
      return this._fiatCurrencys
    },
    async getotcFiatCoinList () {
      const data = await c2cApi.otcFiatCoinList()
      this.$patch(state => {
        state._otcFiatCoinList = data
      })
    },
  },
  init () {
    // this.getCoins()
    // this.getCoinInfos()
    this.getFiatCurrencys()
    this.getotcFiatCoinList()
  },
})

export default useC2cStore
