<template>
  <Flex direction="column" :justifyContent="y" :alignItems="x">
    <slot />
  </Flex>
</template>

<script setup>
const props = defineProps({
  x: {
    default: 's'
  },
  y: {
    default: 's'
  }
})

onBeforeMount(() => {})
</script>

<style lang="scss">
</style>
