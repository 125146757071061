/*
 * @Author: ken yang
 * @Date: 2023-05-08 17:24:27
 * @LastEditTime: 2023-07-25 17:18:24
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/api/order.js
 */
import axios from '@/utils/request'

const placeUrl = '/order/add/user-entrust'
const place = async (body) => {
  const { data } = await axios.post(placeUrl, body)
  return data.data
}

const exchangeListUrl = '/order/user-entrust-list'
const exchangeList = async (body) => {
  const { data } = await axios.post(exchangeListUrl, body)
  return data.data
}

const historyListUrl = '/order/entrust-history-pagination'
const historyList = async (body) => {
  const { data } = await axios.post(historyListUrl, body)
  return data.data
}

const historyDetailUrl = '/order/entrust-detail-page'
const historyDetail = async (body) => {
  const { data } = await axios.post(historyDetailUrl, body)
  return data.data
}

const transactionListUrl = '/order/entrust-detail-list'
const transactionList = async (body) => {
  const { data } = await axios.post(transactionListUrl, body)
  return data.data
}

const cancelUrl = '/order/cancel/entrust'
const cancel = async (body) => {
  const { data } = await axios.post(cancelUrl, body)
  return data.data
}
const cancelAllUrl = '/order/cancel/entrust-new'
const cancelAll = async (body) => {
  const { data } = await axios.post(cancelAllUrl, body)
  return data.data
}
const tradeCountUrl = '/order/current/trade/count'
const tradeCount = async (body = {}) => {
  const { data } = await axios.post(tradeCountUrl, body)
  return data.data
}

const needLoginUrl = [exchangeListUrl, historyListUrl, transactionListUrl, tradeCountUrl]

export default {
  place,
  exchangeList,
  historyList,
  historyDetail,
  transactionList,
  cancel,
  cancelAll,
  tradeCount,
  needLoginUrl,
}
