/*
 * @Author: ken yang
 * @Date: 2022-08-30 11:14:14
 * @LastEditTime: 2023-08-23 13:42:15
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/store/index.js
 */
import { createPinia } from 'pinia'
import useMapStore from '@/store/map.js'
import useUserStore from '@/store/user.js'
import useConfigStore from '@/store/config.js'
import useQuotesStore from '@/store/quotes.js'
import useTradeStore from '@/store/kLine.js'
import useFuturesStore from '@/store/futures.js'
import useWalletStore from '@/store/wallet.js'
import useC2cStore from '@/store/c2c.js'
import useOrderStore from '@/store/order.js'
import useMailStore from '@/store/mail.js'

const pinia = createPinia()

const init = (context) => {
  context.pinia // 使用 `createPinia()` 创建的 pinia
  context.app // 使用 `createApp()` 创建的当前应用程序（仅限 Vue 3）
  context.store // 插件正在扩充的 store
  context.options // 定义存储的选项对象传递给`defineStore()`
  if (context.options.init) {
    context.options.init.call(context.store)
  }
}

pinia.use(init)

export default pinia
export { useMapStore, useUserStore, useConfigStore, useQuotesStore, useTradeStore, useWalletStore, useC2cStore, useOrderStore, useMailStore, useFuturesStore }
