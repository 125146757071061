import AppLayout from '@/layout/AppLayout.vue'

// import Trade from '@/view/trade/Trade.vue'
const Trade = () => import('@/view/trade/Trade.vue')
const GridLayout = () => import('@/view/trade/GridLayout.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/trade',
        props: route => {
          const symbol = route.query.symbol || 'BTC/USDT'
          const tradeStore = useTradeStore()
          tradeStore.symbol = symbol
          if (!route.query.symbol) {
            replaceParamVal('symbol', symbol)
          }
          return { symbol }
        },
        component: Trade,
        meta: { key: 'trade' },
      },
      {
        path: '/gridLayout',
        component: GridLayout,
      },
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
