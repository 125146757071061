const field = (form, rs) => {
  return new Promise(async (resolve, reject) => {
    await form.validateField(rs, (verify, error) => {
      if (error) {
        reject(error)
      } else {
        resolve(true)
      }
    })
  })
}

const fieldAll = (form) => {
  return new Promise(async (resolve, reject) => {
    await form.validate(rs, (verify, error) => {
      if (error) {
        reject(error)
      } else {
        resolve(true)
      }
    })
  })
}

const check = (r, { errorText }) => {
  return (rule, value, callback) => {
    const mapAction = {
      '[object RegExp]': (v) => r.test(v),
      '[object Function]': r,
    }
    const action = mapAction[checkType(r)]
    if (isFunc(errorText)) {
      errorText = errorText()
    }

    if (action(value)) {
      callback()
    } else {
      callback(errorText)
    }
  }
}

export default {
  fieldAll,
  field,
  check
}
