/*
 * @Author: ken yang
 * @Date: 2023-04-06 13:59:42
 * @LastEditTime: 2023-06-17 14:02:20
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/components/table/useTable.js
 */
const useTable = (config) => {
  const data = reactive({
    records: [],
    total: 0,
    pages: {},
    getData: {},
    loading: false,
  })

  const getData = async () => {
    data.loading = true
    const body = {
      ...pages,
      ...config.form,
      ...config.body
    }
    if (!isEmpty(body.time)) {
      body.startTime = body.time[0]
      body.endTime = body.time[1]
    }
    delete body.time
    try {
      const res = await config.requestApi(body)
      data.records = res?.records
      data.total = res?.total
    } catch (error) {
    } finally {
      data.loading = false
    }
  }
  const { pages, confirmAlert } = usePagination(getData, config.pages)
  data.pages = pages
  data.getData = getData
  data.getData()
  return toRefs(data)
}

export default useTable
