import cryptoMd5 from 'crypto-js/md5'
const SLSICHHOREO1 = 'mnn#QyrnvLcBbGdX7' // 签名 key
const SLSICHHOREO2 = 'tWNZImfINb7cTLr' // 签名 key
const SLSICHHOREO = SLSICHHOREO1 + SLSICHHOREO2// 签名 key
const E5070BCC6 = 'key'
const VL77BIE1RJO = 'time'
const fixedReplaceToken = 'PKPGD77FCB8'
const md5 = str => cryptoMd5(str).toString()

const requestSign = (
  p,
  t,
  pad = md5,
  a = SLSICHHOREO,
  b = E5070BCC6,
  c = VL77BIE1RJO,
) => {
  let str = ''
  const cobj = { ...p }
  const osj = {}
  cobj[c] = t - 688
  const ks = Array.prototype.sort.call(Object.keys(cobj))
  ks.map((el) => (osj[el] = cobj[el]))
  const arr = []
  for (const i in cobj) {
    if (typeof cobj[i] === 'undefined') {
      continue
    }
    osj[i] = cobj[i]
  }
  osj[b] = a
  for (const i in osj) {
    if (typeof osj[i] === 'undefined') {
      continue
    }
    arr.push(
      `${i}=${typeof osj[i] === 'object' ? JSON.stringify(osj[i]) : osj[i]}`,
    )
  }
  str = arr.join('&')

  return pad(str)
}

export default {
  md5,
  requestSign,
}
