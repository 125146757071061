const setLocalStorageWithExpiration = (key, value, expirationInDays) => {
  const expirationTime = new Date().getTime() + (expirationInDays * 24 * 60 * 60 * 1000) // 计算过期时间
  localStorage.setItem(key, JSON.stringify({ value, expirationTime })) // 存储键值对，包括值和过期时间
}

const getLocalStorageWithExpiration = (key) => {
  const storedValue = localStorage.getItem(key) // 获取键对应的值
  if (storedValue) {
    const storedObject = JSON.parse(storedValue) // 解析存储的对象
    const expirationTime = storedObject.expirationTime // 获取过期时间
    if (new Date().getTime() < expirationTime) { // 检查值是否过期
      return storedObject.value // 返回值
    } else {
      localStorage.removeItem(key) // 值已过期，删除键值对
    }
  }
  return null // 未找到键或值已过期
}

export {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration
}
