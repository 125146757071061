
const use = (instance) => {
// 添加请求拦截器
  instance.interceptors.request.use(async (config) => {
    config.data = fixTime(config.data, '/')

    return config
  },
  (error) => {
    return Promise.reject(error)
  })
  instance.interceptors.response.use(
    (res) => {
      res.data = fixTime(res.data, '*')

      return res
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export default use
