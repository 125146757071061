<!--
 * @Author: ken yang
 * @Date: 2023-02-07 18:14:33
 * @LastEditTime: 2023-03-02 14:17:13
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/components/dialog/DiaAutoJump.vue
-->
<template>
  <Row class="DiaHeader position-r" x="sb" y="s">
    <Column class="w-100b">
      <slot name="title">
        <span class="title bold">{{ title }}</span>
      </slot>
      <slot name="label">
        <span class="c-info m-t-4 label">{{ label }}</span>
      </slot>
    </Column>
    <IconSvg v-if="showClose" class="position-a top-8 right-0 c-info label-lg pointer close" name="icon-IconClose" @click="onClose" />
  </Row>
</template>
<script setup>

const emit = defineEmits()
const props = defineProps({
  title: {},
  label: {},
  showClose: {
    default: true
  },
})

const onClose = () => {
  emit('close', false)
}

</script>

<style lang="scss">
.DiaHeader{

}
</style>
