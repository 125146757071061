/*
 * @Author: ken yang
 * @Date: 2022-09-28 20:13:54
 * @LastEditTime: 2023-07-25 10:50:44
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/api/c2c.js
 */
import axios from '@/utils/request'

// 发布广告
const advertAddUrl = '/order/publish-advert'
const advertAdd = async (body) => {
  const { data } = await axios.post(advertAddUrl, body)
  return data.data
}

// 隐藏广告
const advertHideUrl = '/order/otc/change/hide'
const advertHide = async (body) => {
  const { data } = await axios.post(advertHideUrl, body)
  return data.data
}

// 隐藏全部广告
const advertHideAllUrl = '/order/otc/change/hide/all'
const advertHideAll = async (body) => {
  const { data } = await axios.post(advertHideAllUrl, body)
  return data.data
}

// 编辑广告
const advertEditUrl = '/order/edit-advert-new'
const advertEdit = async (body) => {
  const { data } = await axios.post(advertEditUrl, body)
  return data.data
}

// 广告详情
const advertDetailUrl = '/order/get-advert-detail'
const advertDetail = async (body) => {
  const { data } = await axios.post(advertDetailUrl, body)
  return data.data
}

// 上下架广告
const advertUpOrDownUrl = '/order/upOrDown-advert'
const advertUpOrDown = async (body) => {
  const { data } = await axios.post(advertUpOrDownUrl, body)
  return data.data
}

// 删除广告
const advertDelUrl = '/order/delete-advert'
const advertDel = async (body) => {
  const { data } = await axios.post(advertDelUrl, body)
  return data.data
}

// 获取我的广告
const myAdvertListUrl = '/order/get-advert-list'
const myAdvertList = async (body) => {
  const { data } = await axios.post(myAdvertListUrl, body)
  return data.data
}

// 获取广告
const advertListUrl = '/order/otc/advert/list'
const advertList = async (body) => {
  const { data } = await axios.post(advertListUrl, body)
  return data.data
}

// 删除收款方式
const payShowUrl = '/user/otc/bind/updateShow'
const payShow = async (body) => {
  const { data } = await axios.post(payShowUrl, body)
  return data.data
}

// 订单列表
const orderListUrl = '/order/current/trade/list'
const orderList = async (body) => {
  const { data } = await axios.post(orderListUrl, body)
  return data.data
}

// 订单取消
const orderCancelUrl = '/order/cancel-order'
const orderCancel = async (body) => {
  const { data } = await axios.post(orderCancelUrl, body)
  return data.data
}

// 订单-我已付款
const orderPayedUrl = '/order/payed-order'
const orderPayed = async (body) => {
  const { data } = await axios.post(orderPayedUrl, body)
  return data.data
}

// 订单详情
const orderDetailUrl = '/order/current/trade/detail'
const orderDetail = async (body) => {
  const { data } = await axios.post(orderDetailUrl, body)
  return data.data
}

// 订单-放行
const orderLetgoUrl = '/order/letgo-order'
const orderLetgo = async (body) => {
  const { data } = await axios.post(orderLetgoUrl, body)
  return data.data
}

// 订单-申诉
const complaintUrl = '/order/submit-appeal'
const complaint = async (body) => {
  const { data } = await axios.post(complaintUrl, body)
  return data.data
}

// 订单-被申诉提交信息
const complaintEditUrl = '/order/target-submit-appeal'
const complaintEdit = async (body) => {
  const { data } = await axios.post(complaintEditUrl, body)
  return data.data
}

// 订单-取消申诉
const complaintCancelUrl = '/order/cancel-appeal'
const complaintCancel = async (body) => {
  const { data } = await axios.post(complaintCancelUrl, body)
  return data.data
}

const coinsUrl = '/account/query/otc-coin-info/list'
const coins = async (body) => {
  const { data } = await axios.post(coinsUrl, body)
  return data.data
}

// 广告商：申请广告商详情页面
const merchantDetailUrl = '/user/merchant/apply/detail'
const merchantDetail = async (body = {}) => {
  const { data } = await axios.post(merchantDetailUrl, body)
  return data.data
}

// 广告商-申请成为商家
const forMerchantUrl = '/user/apply/for/merchant'
const forMerchant = async (body) => {
  const { data } = await axios.post(forMerchantUrl, body)
  return data
}

// 广告商-申请取消商家
const merchantCancelUrl = '/user/cancel/merchant'
const merchantCancel = async (body = {}) => {
  const { data } = await axios.post(merchantCancelUrl, body)
  return data.data
}

// 商家的广告详情
const advertUserInfoUrl = '/user/query/advertUserInfo'
const advertUserInfo = async (body) => {
  const { data } = await axios.post(advertUserInfoUrl, body)
  return data.data
}

// 商家的广告列表
const advertUserListUrl = '/order/otc/user/advert/list'
const advertUserList = async (body) => {
  const { data } = await axios.post(advertUserListUrl, body)
  return data.data
}

// 获取最优广告(快捷下单)
const getOptimalAdvertUrl = '/order/get-optimal-advert'
const getOptimalAdvert = async (body) => {
  const { data } = await axios.post(getOptimalAdvertUrl, body)
  return data.data
}

// 快捷下单
const createOrderCheckUrl = '/order/create-order-check'
const createOrderCheck = async (body) => {
  const { data } = await axios.post(createOrderCheckUrl, body)
  return data
}

// 快捷下单
const createOrderUrl = '/order/create-order'
const createOrder = async (body) => {
  const { data, code } = await axios.post(createOrderUrl, body)
  return data
}

// 法币列表
const fiatCurrencyListUrl = '/thirdOtc/conversion/list'
const fiatCurrencyList = async (body) => {
  const { data } = await axios.post(fiatCurrencyListUrl, body)
  return data.data
}

// 法币列表
const orderTradeListUrl = '/order/current/trade/list'
const orderTradeList = async (body) => {
  const { data } = await axios.post(orderTradeListUrl, body)
  return data.data
}

// 获取汇率单价
const getUnitPriceUrl = '/market/get/fiatByCoinName'
const getUnitPrice = async (body) => {
  const { data } = await axios.post(getUnitPriceUrl, body)
  return data.data
}

// 法币列表
const otcCoinListUrl = '/account/otc/coin-set-list'
const otcFiatCoinList = async (body = {}) => {
  const { data } = await axios.post(otcCoinListUrl, body)
  return data.data
}

// 支付方式列表
const payListUrl = '/user/otc/bind/list'
const payList = async (body) => {
  const { data } = await axios.post(payListUrl, body)
  return data.data
}

// 添加支付方式
const payAddUrl = '/user/otc/bind/add'
const payAdd = async (body) => {
  const { data } = await axios.post(payAddUrl, body)
  return data.data
}

// 添加支付方式
const payUpdateUrl = '/user/otc/bind/update'
const payUpdate = async (body) => {
  const { data } = await axios.post(payUpdateUrl, body)
  return data.data
}

const payCheckUrl = '/user/otc/bind/update'
const payCheck = async (body) => {
  const { data } = await axios.post(payCheckUrl, {
    ...body,
    isShow: 3
  })
  return data.data
}

// 根据法币名称查询支持的收款方式
const getPayTypeByTradeAreaUrl = '/user/otc/getPayTypeByTradeArea'
const getPayTypeByTradeArea = async (body) => {
  const { data } = await axios.post(getPayTypeByTradeAreaUrl, body)
  return data.data
}

// 根据法币名称查询支持的收款方式
const getPayInfoUrl = '/user/otc/getPayInfo'
const getPayInfo = async (body) => {
  const { data } = await axios.post(getPayInfoUrl, body)
  return data.data
}

const readMsgUrl = '/user/otc/chat/read-message'
const readMsg = async (body) => {
  const { data } = await axios.post(readMsgUrl, body)
  return data.data
}

const needLoginUrl = []

export default {
  advertAdd,
  advertDetail,
  advertUpOrDown,
  advertDel,
  advertHide,
  advertHideAll,
  advertEdit,
  myAdvertList,
  advertList,
  payShow,
  payAdd,
  payUpdate,
  payCheck,
  payList,
  orderList,
  orderPayed,
  orderCancel,
  orderDetail,
  orderLetgo,
  complaint,
  complaintEdit,
  complaintCancel,
  coins,
  merchantDetail,
  forMerchant,
  merchantCancel,
  advertUserInfo,
  advertUserList,
  getOptimalAdvert,
  createOrder,
  fiatCurrencyList,
  orderTradeList,
  getUnitPrice,
  otcFiatCoinList,
  createOrderCheck,
  getPayTypeByTradeArea,
  getPayInfo,
  readMsg,
  needLoginUrl,
}
