/*
 * @Author: ken yang
 * @Date: 2023-05-08 17:24:27
 * @LastEditTime: 2023-08-24 11:35:45
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/api/quotes.js
 */
import axios from '@/utils/request'

const coinMarketsUrl = '/market/coinMarkets'
const coinMarkets = async (body) => {
  const { data } = await axios.post(coinMarketsUrl, body, { auth: false })
  return data.data
}

const tickersUrl = '/market/tickers'
const tickers = async (body) => {
  const { data } = await axios.post(tickersUrl, body, { auth: false })
  return data.data
}

const tickersOnTopUrl = '/market/tickersOnTop'
const tickersOnTop = async (body) => {
  const { data } = await axios.post(tickersOnTopUrl, body, { auth: false })
  return data.data
}

const contractTickersUrl = '/contract-market/tickers'
const contractTickers = async (body) => {
  const { data } = await axios.post(contractTickersUrl, body, { auth: false })
  return data.data
}

const kLineUrl = '/market/kLine'
const kLine = async (body) => {
  const { data } = await axios.post(kLineUrl, body, { auth: false })
  return data.data
}

const contractKLineUrl = '/contract-market/kLine'
const contractKLine = async (body) => {
  const { data } = await axios.post(contractKLineUrl, body, { auth: false })
  return data.data
}

const tradeHistoryUrl = '/market/trade'
const tradeHistory = async (body) => {
  const { data } = await axios.post(tradeHistoryUrl, body, { auth: false })
  return data.data
}

const contractTradeHistoryUrl = '/contract-market/trade'
const contractTradeHistory = async (body) => {
  const { data } = await axios.post(contractTradeHistoryUrl, body, { auth: false })
  return data.data
}

const marketDeepsUrl = '/market/deeps'
const marketDeeps = async (body) => {
  const { data } = await axios.post(marketDeepsUrl, body, { auth: false })
  return data.data
}

const contractMarketDeepsUrl = '/contract-market/deeps'
const contractMarketDeeps = async (body) => {
  const { data } = await axios.post(contractMarketDeepsUrl, body, { auth: false })
  return data.data
}

const marketDepthUrl = '/market/depth'
const marketDepth = async (body) => {
  const { data } = await axios.post(marketDepthUrl, body, { auth: false })
  return data.data
}

const contractMarketDepthUrl = '/contract-market/depth'
const contractMarketDepth = async (body) => {
  const { data } = await axios.post(contractMarketDepthUrl, body, { auth: false })
  return data.data
}

const optionalListUrl = '/market/get/optional'
const optionalList = async (body) => {
  const { data } = await axios.post(optionalListUrl, body)
  return data.data
}

const contractOptionalListUrl = '/contract-market/get/optional'
const contractOptionalList = async (body) => {
  const { data } = await axios.post(contractOptionalListUrl, body)
  return data.data
}

const optionalAddUrl = '/market/add/optional'
const optionalAdd = async (body) => {
  const { data } = await axios.post(optionalAddUrl, body)
  return data.data
}

const contractOptionalAddUrl = '/contract-market/add/optional'
const contractOptionalAdd = async (body) => {
  const { data } = await axios.post(contractOptionalAddUrl, body)
  return data.data
}

const optionalDleUrl = '/market/delete/optional'
const optionalDle = async (body) => {
  const { data } = await axios.post(optionalDleUrl, body)
  return data.data
}
const contractOptionalDleUrl = '/contract-market/delete/optional'
const contractOptionalDle = async (body) => {
  const { data } = await axios.post(contractOptionalDleUrl, body)
  return data.data
}

const coinIntroductionUrl = '/account/query/coin-introduction'
const coinIntroduction = async (body) => {
  const { data } = await axios.post(coinIntroductionUrl, body)
  return data.data
}

const contractCoinmarketUrl = '/contract-market/get-all-contract-coinmarket'
const contractCoinmarket = async (body) => {
  const { data } = await axios.post(contractCoinmarketUrl, body)
  return data.data
}

const hotUrl = '/market/getRiseAndFall'
const hot = async (body = {}) => {
  const { data } = await axios.post(hotUrl, body, { auth: false })
  console.log('___data', data)
}

const getRiseAndFallUrl = '/market/getRiseAndFall'
const getRiseAndFall = async (body) => {
  const { data } = await axios.post(getRiseAndFallUrl, body)
  return data.data
}

const plateListUrl = '/market/plate/list'
const plateList = async (body) => {
  const { data } = await axios.post(plateListUrl, body)
  return data.data
}
const plateTickersUrl = '/market/plate/tickers'
const plateTickers = async (body) => {
  const { data } = await axios.post(plateTickersUrl, body)
  return data.data
}

const plateNewTickersUrl = '/market/newCoinMarket/tickers'
const plateNewTickers = async (body) => {
  const { data } = await axios.post(plateNewTickersUrl, body)
  return data.data
}

const rankingsConfigUrl = '/market/rankings-config'
const rankingsConfig = async (body) => {
  const { data } = await axios.post(rankingsConfigUrl, body)
  return data.data
}

const needLoginUrl = []

export default {
  coinMarkets,
  tickers,
  tickersOnTop,
  contractTickers,
  kLine,
  contractKLine,
  tradeHistory,
  marketDeeps,
  marketDepth,
  contractMarketDeeps,
  optionalList,
  optionalAdd,
  contractOptionalAdd,
  optionalDle,
  contractOptionalDle,
  coinIntroduction,
  contractCoinmarket,
  contractTradeHistory,
  contractOptionalList,
  contractMarketDepth,
  hot,
  getRiseAndFall,
  plateList,
  plateTickers,
  plateNewTickers,
  rankingsConfig,
  needLoginUrl,
}
