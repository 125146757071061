import AppLayout from '@/layout/AppLayout.vue'
// import Home from '@/view/home/Home.vue'
const Rebate = () => import('@/view/rebate/Rebate.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/rebate',
        component: Rebate,
        meta: { key: 'rebate' },
      },
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
