/*
 * @Author: ken yang
 * @Date: 2022-09-28 20:13:54
 * @LastEditTime: 2023-05-31 13:53:44
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/api/user.js
 */
import axios from '@/utils/request'

const sendMessageUrl = '/user/otc/send-message'
const sendMessage = async (body) => {
  const { data } = await axios.post(sendMessageUrl, body)
  return data.data
}

const recordsUrl = '/user/otc/chat/records'
const records = async (body) => {
  const { data } = await axios.post(recordsUrl, body)
  return data.data
}

const needLoginUrl = []

export default {
  sendMessage,
  records,
  needLoginUrl,
}
