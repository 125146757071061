
const reserveUrlQuery = (route) => {
  const queryKey = Object.keys(route.query).filter(k => URL_QUERY.includes((k)))
  const query = queryKey.reduce((o, k) => {
    return { ...o, [k]: route.query[k] }
  }, {})
  return query
}

export default reserveUrlQuery
