const intervals = [
  {
    name: '1m',
    value: 'one_minute',
    interval: '1',
  },
  {
    name: '5m',
    value: 'five_minute',
    interval: '5',
  },
  {
    name: '15m',
    value: 'fifteen_minute',
    interval: '15',
  },
  {
    name: '30m',
    value: 'thirty_minute',
    interval: '30',
  },
  {
    name: '1H',
    value: 'one_hour',
    interval: '60',
  },
  {
    name: '4H',
    value: 'four_hour',
    interval: '60',
  },
  {
    name: '1D',
    value: 'one_day',
    interval: '60',
  },
  {
    name: '1W',
    value: 'one_week',
    interval: '1W',
  },
  {
    name: '1M',
    value: 'one_month',
    interval: '1W',
  },
]

const filterData = (cb, symbol, interval) => {
  return ({ data }) => {
    if (symbol == data?.coinMarket) {
      const klines = data.klines
      cb(klines[interval])
    }
  }
}

const wsKeys = {
  ticker: 'alpha-market-ticker'
}
const chainOff = (re) => {
  const ws = Socket.autoConnect()

  for (let i = 0; i < ws?.eventsKeys?.length; i++) {
    const eKey = ws?.eventsKeys[i]
    if (re.test(eKey)) {
      ws.off(eKey)
    }
  }
}
const useTradeStore = defineStore('tradeStore', {
  state: () => {
    return {
      // theme: localStorage.theme ?? 'light', // light dark
      intervalName: localStorage.kLineIntervalName || intervals[1].name,
      symbol: localStorage.symbol ?? '',
      kLineCbs: {},
      renderStateEd: 1,
    }
  },
  getters: {
    intervals: () => intervals,
    interval: (state) => intervals.find(e => e.name == state.intervalName),
  },
  actions: {
    // -- KLine --
    async subKLine (symbol, intervalName, cb) {
      const ws = Socket.autoConnect()

      this.kLineCbs[symbol] = cb
      chainOff(new RegExp(`${WS_KEYS.ticker}@KLine`))

      const key = `${WS_KEYS.ticker}@KLine-${symbol}`
      const intervalInfo = intervals.find(e => e.name == intervalName)
      const ecb = filterData(cb, symbol, intervalInfo.value)

      ws.on(key, ecb, false)
    },
    async switchKLine (symbol, intervalName, cb) {
      const re = new RegExp(`${WS_KEYS.ticker}@KLine`)
      this.kLineCbs = {}
      this.subKLine(symbol, intervalName, cb)
    },
    async unsubKLineEvent (symbol) {
      const ws = Socket.autoConnect()

      const key = `${WS_KEYS.ticker}@KLine-${symbol}`

      ws.off(key)
    },
    async switchInterval (symbol, intervalName) {
      this.intervalName = intervalName
      this.unsubKLineEvent(symbol, false)
      this.subKLine(symbol, intervalName, this.kLineCbs[symbol])
    },
    // -- KLine --

    // -- Price --
    async subPrice (symbol, cb) {
      const ws = Socket.autoConnect()

      const key = `${WS_KEYS.ticker}@price-${symbol}`
      const ecb = ({ data }) => {
        if (symbol == data?.coinMarket) {
          cb(data)
        }
      }

      ws.on(key, ecb, false)
    },
    async unsubPrice (symbol) {
      const ws = Socket.autoConnect()

      const key = `${WS_KEYS.ticker}@price-${symbol}`

      ws.off(key)
    },
    async switchPrice (symbol, cb) {
      const re = new RegExp(`${WS_KEYS.ticker}@price`)
      chainOff(re)
      this.subPrice(symbol, cb)
    },
    // -- Price --
    // -- TradeHistory --
    async subTradeHistory (symbol, cb) {
      const ws = Socket.autoConnect()

      const key = `${WS_KEYS.ticker}@tradeHistory-${symbol}`
      const ecb = ({ data }) => {
        if (symbol == data?.coinMarket) {
          cb(data.trade)
        }
      }

      ws.on(key, ecb, false)
    },
    async unsubTradeHistory (symbol) {
      const ws = Socket.autoConnect()
      const key = `${WS_KEYS.ticker}@tradeHistory-${symbol}`
      ws.off(key)
    },
    async switchTradeHistory (symbol, cb) {
      const re = new RegExp(`${WS_KEYS.ticker}@tradeHistory`)
      chainOff(re)
      this.subTradeHistory(symbol, cb)
    },
    // -- TradeHistory --
    // -- Depth --
    async subDepth (symbol, cb) {
      const ws = Socket.autoConnect()

      const eventName = `alpha-market-depth-${symbol.replace(/\//, '-').toLowerCase()}-trade`
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': eventName,
        'data': {}
      }
      ws.on(sendData.topic, ({ data }) => cb(data), false)
      ws.send(sendData)
    },
    unsubDepth (symbol) {
      const ws = Socket.autoConnect()
      const eventName = `alpha-market-depth-${symbol.replace(/\//, '-').toLowerCase()}-trade`
      if (!ws.hasEvent(eventName)) {
        return
      }

      const sendData = {
        'cmd': 'unsub',
        'topic': eventName,
        'data': {}
      }

      ws.off(sendData.topic)
      ws.send(sendData)
    },
    async switchDepth (symbol, cb) {
      const ws = Socket.autoConnect()
      const re = /^alpha-market-depth-(.+)-trade$/
      const eventName = `alpha-market-depth-${symbol.replace(/\//, '-').toLowerCase()}-trade`
      const previousEventName = ws?.eventsKeys.find(e => re.test(e))
      if (previousEventName == eventName) {
        return
      }
      if (!isEmpty(previousEventName)) {
        const sendData = {
          'cmd': 'unsub',
          'topic': previousEventName,
          'data': {}
        }
        ws.off(sendData.topic)
        ws.send(sendData)
      }
      chainOff(re)
      this.subDepth(symbol, cb)
    },
    // -- Depth --
    // -- DepthPaint --
    async subDepthPaint (symbol, cb) {
      const ws = Socket.autoConnect()

      const eventName = `alpha-market-depth-${symbol.replace(/\//, '-').toLowerCase()}-paint`
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': eventName,
        'data': {}
      }

      ws.on(sendData.topic, ({ data }) => cb(data), false)
      ws.send(sendData)
    },
    unsubDepthPaint (symbol) {
      const ws = Socket.autoConnect()
      const eventName = `alpha-market-depth-${symbol.replace(/\//, '-').toLowerCase()}-paint`
      if (!ws.hasEvent(eventName)) {
        return
      }
      const sendData = {
        'cmd': 'unsub',
        'topic': eventName,
        'data': {}
      }

      ws.off(sendData.topic)
      ws.send(sendData)
    },
    async switchDepthPaint (symbol, cb) {
      try {
        const ws = Socket.autoConnect()
        const re = /^alpha-market-depth-(.+)-paint$/
        const eventName = `alpha-market-depth-${symbol.replace(/\//, '-').toLowerCase()}-paint`
        const previousEventName = ws?.eventsKeys.find(e => re.test(e))
        if (previousEventName == eventName) {
          return
        }
        if (!isEmpty(previousEventName)) {
          const sendData = {
            'cmd': 'unsub',
            'topic': previousEventName,
            'data': {}
          }
          ws.off(sendData.topic)
          ws.send(sendData)
        }
        chainOff(re)
        this.subDepthPaint(symbol, cb)
      } catch (e) {
        console.log('switchDepthPaint', e)
      }
    },
    // -- DepthPaint --
  },
  init () {
    this.$subscribe((mutation, state) => {
      localStorage.kLineIntervalName = state.intervalName
      localStorage.symbol = state.symbol
    })
  },
})

export default useTradeStore
