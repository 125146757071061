/*
 * @Author: ken yang
 * @Date: 2022-09-09 11:34:10
 * @LastEditTime: 2023-08-01 15:33:06
 * @LastEditors: ken yang
 * @Description: 订单store
 * @FilePath: /uexch_pc/src/store/order.js
 */

const useOrderStore = defineStore('orderStore', {
  state: () => {
    return {
      tradeCount: 0,
      otcWsData: {}, // 订单推送的数据
    }
  },
  getters: {
    getTradeCount (state) {
      return state.tradeCount
    },
    getOtcWsData (state) {
      return state.otcWsData
    }
  },
  actions: {
    setTradeCount (num) {
      this.$patch(state => {
        state.tradeCount = num
      })
    },
    subTradeCount () {
      const ws = Socket.autoConnect()
      const eventName = 'alpha-order-center-order-otc-list'
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': eventName,
        'data': {}
      }
      ws.on(sendData.topic, ({ data }) => {
        this.$patch(state => {
          state.tradeCount = data?.orderCount
          state.otcWsData = data
        })
      }, false)
      ws.send(sendData)
    },
    unSubTradeCount () {
      const ws = Socket.autoConnect()
      const eventName = 'alpha-order-center-order-otc-list'
      const sendData = {
        'cmd': 'unsub',
        'topic': eventName,
        'data': {}
      }
      ws.off(sendData.topic)
      ws.send(sendData)
    }
  },
  init () {
  }
})

export default useOrderStore
