import axios from '@/utils/request'

const agentTypeUrl = '/user/agent/type'
const agentType = async (body = {}) => {
  const { data } = await axios.post(agentTypeUrl, body)
  return data.data
}

const jumpLoginUrl = '/user/agent/jump-login'
const jumpLogin = async (body = {}) => {
  const { data } = await axios.post(jumpLoginUrl, body)
  return data.data
}

const agentAppleyUrl = '/user/agent/apply'
const agentAppley = async (body = {}) => {
  const { data } = await axios.post(agentAppleyUrl, body)
  return data.data
}

const needLoginUrl = []

export default {
  needLoginUrl,
  agentType,
  jumpLogin,
  agentAppley
}
