/*
 * @Author: ken yang
 * @Date: 2022-11-10 09:59:44
 * @LastEditTime: 2022-11-10 10:35:34
 * @LastEditors: ken yang
 * @Description: 获取当前语言en或者zh
 * @FilePath: /uexch_h5/src/utils/globalFunc/lang.js
 */

const lang = () => {
  const configStore = useConfigStore()
  if (configStore.locale == LANGCN) {
    return 'zh'
  } else if (configStore.locale == LANGEN) {
    return 'en'
  } else if (configStore.locale == LANGTW) {
    return 'tw'
  }
}

export default lang
