let Xlsx
const downloadExcel = async (data, name = randomString()) => {
  if (!Xlsx) {
    Xlsx = await import('xlsx')
  }

  const d = Xlsx.utils.json_to_sheet(data)
  const wb = Xlsx.utils.book_new()
  Xlsx.utils.book_append_sheet(wb, d, 'kalacloud-data')
  Xlsx.writeFile(wb, `${name}.xlsx`)
}

export default downloadExcel
