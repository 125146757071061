const assignObj = (target, origin) => {
  try {
    if (!(target instanceof Object) || !(origin instanceof Object)) {
      throw new Error('origin or target is not Object')
    }
    Object.keys(origin).forEach(key => {
      target[key] = origin[key]
    })
    return target
  } catch (error) {
    console.error('assignObj', error)
    return null
  }
}
export default assignObj
