import axios from '@/utils/request'

const uploadFileUrl = '/user/file/upload-file'
const uploadFile = async (body = {}) => {
  const { data } = await axios.post(uploadFileUrl, body, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data.data
}

const needLoginUrl = []

export default {
  needLoginUrl,
  uploadFile
}
