/**
 *
 * @param {*} keys 如果是all 表示全部清除；如果是['key1','key2']表示清除key1、key2
 */
const clearStorage = (keys) => {
  // console.log('111', keys)
  // console.log('222', import.meta.env.VITE_APP_VERSION_TIME)
  // console.log('333', localStorage.VITE_APP_VERSION_TIME)
  // 对比版本号，如果与上一次不同那么就做清除业务
  if (localStorage.VITE_APP_VERSION_TIME !== import.meta.env.VITE_APP_VERSION_TIME) {
    if (keys === 'all') {
      localStorage.clear()
    } else {
      keys.forEach(function (key) {
        localStorage.removeItem(key)
      })
    }
    localStorage.VITE_APP_VERSION_TIME = import.meta.env.VITE_APP_VERSION_TIME
  }
}

export default clearStorage

