/*
 * @Author: ken yang
 * @Date: 2022-09-07 18:07:16
 * @LastEditTime: 2023-08-30 18:25:22
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/utils/request/index.js
 */
import axios from 'axios'
import useFixTime from './fixTime'
import useDelNullKey from './delNullKey'
import DiaWarn from '@/components/dialog/DiaWarn.js'
// development
const baseURL = serveUrl
const instance = axios.create({
  baseURL: baseURL,
})

// 不需要弹报错的code
const noTipsCode = [18040, 18111]

const okCode = 1
const warnCode = -1
const needLoginCode = 50
const upgradeCode = 700
let canAlertError = true

const alertError = (str, t = 3000) => {
  if (!canAlertError) {
    return
  }
  ElMessage.error(str)
  canAlertError = false
  setTimeout(() => {
    canAlertError = true
  }, t)
}

useFixTime(instance)
useDelNullKey(instance)

// 添加请求拦截器
instance.interceptors.request.use(async (config) => {
  const userStore = useUserStore()
  const configStore = useConfigStore()
  const time = +new Date()

  config.headers.time = time
  config.headers.deviceType = 3
  const obj = fixTime(delNullKey(config.data || {}), '/')
  config.headers.signature = encrypt.requestSign(obj, time)
  config.headers.language = configStore.locale

  if (config.auth === false) {
    delete config.headers.token
  } else {
    if (userStore.token) {
      config.headers.token = userStore.token
    } else {
      delete config.headers.token
    }
  }
  return config
})

// 白名单报错
const errorDia = (data) => {
  if (!noTipsCode.includes(data.code)) {
    alertError(data.message)
  }
}

instance.interceptors.response.use(
  (res) => {
    const userStore = useUserStore()
    const { config, data } = res
    // data.code = upgradeCode

    if (data.code == needLoginCode) {
      // state.token
      errorDia(data)
      userStore.logout()
      return Promise.reject(data)
    } else if (data.code === upgradeCode) {
      router.push('/upgrade')
      return Promise.reject(data)
    } else if (data.code === warnCode) {
      DiaWarn({ data: data.message })
    } else if (data.code != okCode) {
      errorDia(data)
      return Promise.reject(data)
    }
    return res
  },
  async (error) => {
    const userStore = useUserStore()
    const response = error?.response
    const httpCode = response?.status
    console.log('请求错误', error)
    if (httpCode != 200) {
      alertError($t('public.networkError'))
      return Promise.reject($t('public.networkError'))
    }

    return Promise.reject(error)
  }
)

const needLoginFilter = (cb) => {
  return (...age) => {
    const userStore = useUserStore()
    const url = age[0]
    if (needLoginUrl.includes(url) && isEmpty(userStore.token)) {
      // throw new Error(`url:${url} 需要登录发送，已被拦截！`)
      return
    }
    return cb(...age)
  }
}

instance.get = needLoginFilter(instance.get)
instance.post = needLoginFilter(instance.post)
instance.put = needLoginFilter(instance.put)
instance.patch = needLoginFilter(instance.patch)
instance.delete = needLoginFilter(instance.delete)

export default instance
