<!--
 * @Author: ken yang
 * @Date: 2023-02-01 14:17:09
 * @LastEditTime: 2023-02-01 15:04:53
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/view/userCenter/SecurityCenter/components/DiaChangeName.vue
-->
<template>
  <el-dialog
    class="DiaTradeStyleSet dialog-d"
    modal-class="dialog-m-center"
    :modelValue="modelValue"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    append-to-body
    width="500px"
    @close="onClose"
  >
    <template #header>
      <Column class="w-100b" x="s">
        <Row class="font-24 bold m-b-2" x="c">{{ $t("mail.messageSet") }}</Row>
        <Row class="label c-info" x="c">{{ $t("mail.messageSetContent") }}</Row>
      </Column>
    </template>

    <Column class="w-100b m-b-30">
      <!-- {{ mailStore.switch }} -->
      <Row
        v-for="(k) in typeKeys"
        :key="k"
        class="h-50 w-100b border-b"
        y="c"
        x="sb"
      >
        <span>{{ $t(`mail.typeMap.${k}`) }}</span>
        <el-switch
          :inactive-value="1"
          :active-value="0"
          :modelValue="mailStore.switch[k]"
          @click="setInfoEdit(k)"
        />
      </Row>
    </Column>
  </el-dialog>
</template>
<script setup>
const c2cStore = useC2cStore()
const userStore = useUserStore()
const emit = defineEmits()
const props = defineProps({
  modelValue: {
    default: false
  },
})

const configStore = useConfigStore()
const mailStore = useMailStore()
let loading = $ref(false)

const typeKeys = ['msgPlatform', 'msgTrade', 'msgFinance', 'msgSys']

const onClose = () => {
  emit('update:modelValue', false)
}
const setInfoEdit = async (k) => {
  try {
    loading = true
    const value = mailStore.switch[k] == 1 ? 0 : 1
    const data = await mailApi.setInfoEdit({ [k]: value })
    await mailStore.getSetInfo()
  } finally {
    loading = false
  }
}
const setTradeStyle = (s) => {
  configStore.setTradeStyle(s)
  onClose()
}

watch(() => props.modelValue, (show) => {
  if (show) {
    mailStore.getSetInfo()
  }
})
</script>
<style lang="scss">
.DiaTradeStyleSet{
  .el-dialog__headerbtn{
    top: 0 !important;
    right: 0 !important;
  }
}
</style>
