import pako from 'pako'

const praseWsBlob = (data) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (event) {
      try {
        const arrayBuffer = event.target.result // 解析后的ArrayBuffer
        const decompressedData = pako.inflate(arrayBuffer, { to: 'string' })
        resolve(JSON.parse(decompressedData))
      } catch (error) {
        reject(error)
      }
    }

    reader.readAsArrayBuffer(data)
  })
}

export default praseWsBlob
