<!--
 * @Author: ken yang
 * @Date: 2023-04-06 09:33:01
 * @LastEditTime: 2023-07-21 14:29:16
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/components/table/Bitable.vue
-->
<template>
  <div>
    <Row v-if="isSearchHeader" y="c">
      <slot name="searchHeader" :getData="getData">
        <slot name="searchHeaderFront" />
        <Row class=" w-100b p-lr-20 h-42  header-wrap">
          <DynamicForm
            :fields="fields"
            :hasReset="true"
            :fontSize="12"
            :fieldHeight="28"
            :spaceX="10"
            :class="[formClass,'h-28']"
            @change="(k,v) => form[k] = v"
            @submit="getData"
            @reset="getData"
          >
            <template #end>
              <DownloadExcel
                v-if="isDownExcel"
                ref="downloadExcel"
                v-model:loading="loading"
                :form="form"
                :apiFn="requestApi"
                :format="config"
                class="m-r-20"
              />
              <slot name="searchHeaderEnd" />
            </template>
          </DynamicForm>
        </Row>
      </slot>
    </Row>

    <DownloadExcel
      v-else
      v-show="false"
      ref="downloadExcel"
      :form="form"
      :apiFn="requestApi"
      :format="config"
    />

    <BasicTable
      v-loading="!closeLoading&&loading"
      :class="tableClass"
      :config="config"
      :data="records"
      :isSerial="isSerial"
      :isDraggable="isDraggable"
      :cellClass="cellClass"
      :headerCellClass="headerCellClass"
      :headerClass="headerClass"
      :actionWidth="actionWidth"
      :rowClassName="rowClassName"
      @onStart="(e)=>emit('onStart',e)"
      @onEnd="(e)=>emit('onEnd',e)"
      @row-click="rowClick"
      @onSort="onSort"
      @expandChange="(e)=>emit('expandChange',e)"
    >
      <template v-for="(item,index) in Object.keys(slots)" #[item]="data" :key="index">
        <slot :name="item" v-bind="data || {}" />
      </template>

      <template v-if="Object.keys(slots).includes('action')" #action="data">
        <slot name="action" v-bind="data" />
      </template>

      <template v-if="Object.keys(slots).includes('expand')" #expand="data">
        <slot name="expand" v-bind="data" />
      </template>
    </BasicTable>
    <Pagination
      v-if="isPagination && records?.length < total"
      v-model:pageNum="pages.pageNum"
      v-model:pageSize="pages.pageSize"
      :class="`${paginationClass} m-t-40`"
      :total="total"
    />
  </div>
</template>
<script setup>
import useTable from '@/components/table/useTable.js'
const emit = defineEmits(['update:getData', 'update:records', 'row-click'])

const downloadExcel = $ref(null)

// 如果没有插入searchHeader，就需要传入form、fields
const props = defineProps({
  config: {
    require: true
  },
  requestApi: {
    require: true
  },
  form: {},
  fields: {},
  isDownExcel: {
    default: false
  },
  isDraggable: {
    default: false
  },
  records: {
    default: []
  },
  body: {
    default: {}
  },
  tableClass: {},
  formClass: {},
  isSearchHeader: {
    default: true,
  },
  cusPages: {},
  isPagination: {
    default: true
  },
  paginationClass: {},
  cellClass: {},
  headerClass: {},
  headerCellClass: {},
  rowClassName: {},
  isSerial: {},
  actionWidth: {},
  closeLoading: {
    default: false
  }
})

const slots = useSlots()

const { getData, records, total, pages, loading } = useTable({
  requestApi: props.requestApi,
  form: props.form,
  body: props.body,
  pages: props.cusPages ?? null
})

const onSort = ({ column, prop, order }) => {
  // getData()
}
const rowClick = (row, cloumn) => {
  emit('row-click', row, cloumn)
}

const download = () => {
  downloadExcel.downLoad()
}
defineExpose({ downLoad: download })

onBeforeMount(() => {
  emit('update:getData', getData.value)
})

watch(
  () => { return records.value },
  () => {
    emit('update:records', records.value)
  }
)

</script>

<style lang="scss" scoped>
.header-wrap{
  background-color: #EBF2FA !important;
}
</style>
