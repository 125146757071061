/*
 * @Author: ken yang
 * @Date: 2022-09-22 19:58:25
 * @LastEditTime: 2022-09-28 15:37:34
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/api/config.js
 */
import axios from '@/utils/request'

const bannerListUrl = '/user/banners'
const bannerList = async (body) => {
  const { data } = await axios.post(bannerListUrl, body, { auth: false })
  return data.data
}

const channelVerifyUrl = '/user/channel/validate'
const channelVerify = async (body) => {
  const { data } = await axios.post(channelVerifyUrl, body, { auth: false })
  return data.data
}

const noticeListUrl = '/user/announcements'
const noticeList = async (body) => {
  const { data } = await axios.post(noticeListUrl, body, { auth: false })
  return data.data
}

const noticeInfoUrl = '/ucenter/noticeinfo/detail'
const noticeInfo = async (params) => {
  const { data } = await axios.get(noticeInfoUrl, { params })
  return data.data
}

const feesUrl = '/account/get-user-exchange-fee'
const fees = async (body) => {
  const { data } = await axios.post(feesUrl, body, { auth: false })
  return data.data
}

const feeLevelsUrl = '/order/get-exchange-fee-levels'
const feeLevels = async (body) => {
  const { data } = await axios.post(feeLevelsUrl, body, { auth: false })
  return data.data
}

const coinInfoUrl = '/account/get/trade/coinId/info'
const coinInfo = async (body) => {
  const { data } = await axios.post(coinInfoUrl, body, { auth: false })
  return data.data
}

const downloadUrlUrl = '/user/get/sys-config'
const downloadUrl = async (body = {}) => {
  const { data } = await axios.post(downloadUrlUrl, body, { auth: false })
  return data.data
}

const withdrawConfigUrl = '/account/withdraw-config'
const withdrawConfig = async (body = {}) => {
  const { data } = await axios.post(withdrawConfigUrl, body)
  return data.data
}

const switchThemeUrl = '/user/switchTheme'
const switchTheme = async (body = {}) => {
  const { data } = await axios.post(switchThemeUrl, body)
  return data.data
}

const needLoginUrl = []

export default {
  bannerList,
  channelVerify,
  noticeList,
  noticeInfo,
  fees,
  feeLevels,
  coinInfo,
  downloadUrl,
  withdrawConfig,
  switchTheme,
  needLoginUrl,
}
