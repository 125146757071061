const lan = /^.{8,32}$/
const lan5 = /^.{5,32}$/
const lan64 = /^.{8,64}$/
const number6 = /^\d{6}$/
const number = /^[0-9]*$/

const phone = /^\d{5,}$/
// const email = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
const email = /^([a-z0-9A-Z-|_｜+|\.]?)+@([a-z0-9A-Z-|_|+|\.]?)+\.+[a-zA-Z]{2,}$/
const pwd = /^(?:(?=.*[A-Za-z])(?=.*[0-9])).{8,24}$/

const checkEmail = (rule, value, callback) => {
  if (email.test(value) && value.length <= 255) {
    callback()
  } else {
    callback($t('form.emailError'))
  }
}

export default {
  lan,
  lan5,
  lan64,
  number6,
  number,
  phone,
  email,
  pwd,
  checkEmail,
}
