<!--
 * @Author: ken yang
 * @Date: 2023-02-14 17:23:16
 * @LastEditTime: 2023-03-16 11:08:44
 * @LastEditors: ken yang
 * @Description: 眼睛
 * @FilePath: /uexch_pc/src/components/widget/Eyes.vue
-->
<template>

  <Row x="c" y="c" class="RedDot bg-error c-white radius-7 w-min-14 h-min-14 lh-14 p-lr-4 label-sm" :class="position ? 'RedDot-position' :''">
    {{ numLan <= 3? num : '···' }}
  </Row>
</template>
<script setup>
// :style="`right: -${4+ 3 * 2}`"
const props = defineProps({
  num: {
    default: 0
  },
  position: {
    default: false,
  }
})

const numLan = $computed(() => props.num.toString().length)
const getRight = () => {
  const rightMap = {
    1: 2,
    2: 6,
    3: 12,
  }
  return rightMap[numLan] ?? 12
}
const translateX = $computed(() => `translateX(${getRight()}px)`)

</script>
<style lang="scss">
.RedDot-position{
  position: absolute;
  transform: v-bind(translateX);
}
</style>
