const defaultUrl = decodeURI(decodeURI(window.location.href))

function getUrlQuery (url) {
  url = url ?? defaultUrl
  var str = url.split('?')[1]
  if (!str) {
    return {}
  }
  var keys = str.split('&')
  var obj = {}
  keys.forEach((item, idx, data) => {
    var arr = item.split('=')
    obj[arr[0]] = arr[1]
  })
  return obj
}
export default getUrlQuery
