
const use = (instance) => {
  // 添加请求拦截器
  instance.interceptors.request.use(async (config) => {
    if (config.headers['Content-Type'] != 'multipart/form-data' && config.method == 'post' && config.data) {
      config.data = delNullKey(config.data)
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  })
}

export default use
