/*
 * @Author: ken yang
 * @Date: 2022-10-25 15:24:13
 * @LastEditTime: 2023-08-24 17:30:35
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/utils/constant.js
 */
const PAGES = {
  pageNum: 1,
  pageSize: 10,
}

const PAGES_STYLE_2 = {
  pageNum: 1,
  pageSize: 6,
}

const UPLOAD = {
  size: 10
}

const SMALL_AMOUNT = 0

const URL_QUERY = ['winId', 'locale', 'token']

const LANGEN = 'en_us'
const LANGCN = 'zh_cn'
const LANGTW = 'zh_tw'

const WS_KEYS = {
  ticker: 'alpha-market-ticker',
  userCenter: 'alpha-user-center-chat',
  order: 'alpha-market-order',
  mail: 'alpha-user-center-msg-send'
}

const WS_CONTRACT_KEYS = {
  ticker: 'alpha-market-ticker-contract',
  position: 'account-user-usdt-contract-position',
  openOrders: 'alpha-market-order-contract',
  stop: 'alpha-market-order-contract-stop',
}

const COUNTDOWN = 120

const USDTUNITKEY = '0' // 名义USDT
const PCSUNITKEY = '1' // 张
const SYMUNITKEY = '2' // 币
const CUSDTUNITKEY = '3' // 成本USDT

const CROSS = 0 // 全仓
const SHARED = 1 // 租仓

const MERGE = 1 // 合仓
const SPLIT = 2 // 分仓

const SEPRJ = 'S' // 自研合约
const FUPRJ = 'F' // timi合约项目

export {
  PAGES,
  PAGES_STYLE_2,
  UPLOAD,
  URL_QUERY,
  SMALL_AMOUNT,
  LANGEN,
  LANGCN,
  LANGTW,
  WS_KEYS,
  WS_CONTRACT_KEYS,
  COUNTDOWN,
  USDTUNITKEY,
  PCSUNITKEY,
  SYMUNITKEY,
  CUSDTUNITKEY,
  CROSS,
  SHARED,
  SPLIT,
  MERGE,
  SEPRJ,
  FUPRJ
}
