/*
 * @Author: ken yang
 * @Date: 2023-05-31 17:36:33
 * @LastEditTime: 2023-05-31 17:38:34
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/utils/globalFunc/borwserLang.js
 */
const lang = () => {
  const rowLang = navigator.language || navigator.userLanguage
  if (rowLang?.toLocaleLowerCase()?.includes('CN') || rowLang?.toLocaleLowerCase()?.includes('zh')) return LANGCN
  if (rowLang?.toLocaleLowerCase()?.includes('TW')) return LANGTW
  if (rowLang?.toLocaleLowerCase()?.includes('en')) return LANGEN
  return LANGCN
}

export default lang
