/*
 * @Author: ken yang
 * @Date: 2023-06-26 16:12:17
 * @LastEditTime: 2023-07-03 14:38:26
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/components/dialog/DiaWarn.js
 */
import DiaWarn from './DiaWarn.vue'

const messageBox = async (data,) => {
  const mountNode = document.createElement('div')
  const { app, router } = await BiCreateApp(DiaWarn, mountNode, {
    ...data,
    close: () => {
      data?.closed && data?.closed()

      app.unmount(mountNode)
      document.body?.removeChild(mountNode)
    },
    onLeft: () => {
      data?.onLeft && data?.onLeft()
    },
    onRight: () => {
      data?.onRight && data?.onRight()
    }
  })
  document.body.appendChild(mountNode)
}

export default messageBox
