const TIMER_NUM = 45000

let _subIds = {}
let _hasTimer = false
let _pingTime = null
let _isReconnect = false

let id = 1
const _runTimer = () => {
  if (_hasTimer == false) {
    _hasTimer = true
    runTimer(() => {
      if (_isReconnect) {
        console.log(`socket 重新连接，连接时间：${dayjs(Date.now()).format('YYYY-MM-DD HH:mm')}，上次ping时间：${dayjs(_pingTime).format('YYYY-MM-DD HH:mm')}，`)
        Socket.instance?.close()
        const quotesStore = useQuotesStore()
        const userStore = useUserStore()
        const mailStore = useMailStore()

        userStore.subOrder()
        quotesStore.subPrice()
        mailStore.subRecords()
        eventBus.emit('WebSocket-Reconnect')
      }
      _isReconnect = true
      return true
    }, TIMER_NUM)
  }
}

class MSocket {
  static instance = null
  static autoConnect () {
    if (!this.instance) {
      this.instance = new MSocket()
      this.instance.connect()
    }
    return this.instance
  }

  static instanceClose () {
    if (this.instance) {
      this.instance.ws?.close()
      this.instance.ws = null
      this.instance.events = {}
      this.instance.sends = {}
      this.instance = null

      // _runTimer
      _subIds = {}
      // _pingTime = null
      _isReconnect = false
    }
  }

  id = null

  // 和服务端连接的socket对象
  ws = null
  // 存储回调函数
  events = {}

  get eventsKeys () {
    return Object.keys(this.events)
  }

  hasEvent (key) {
    const re = new RegExp(key)
    return this.eventsKeys.some(k => re.test(k))
  }

  sends = {}

  //  定义连接服务器的方法
  async connect () {
    console.log('ws connect')
    this.id = id++
    if (!this.ws) {
      const userStore = useUserStore()
      let url
      try {
        if (userStore.token) {
          await userStore.getInfo()
        }
        url = userStore.token ? wsUrl + userStore.token : wsUrl
      } catch (e) {
        url = wsUrl
      }

      this.ws = new WebSocket(url)

      _runTimer()
      this.initWsEvents()
    }
  }

  initWsEvents () {
    // 得到服务端发送过来的数据
    this.ws.onmessage = async (msg) => {
      let data = null
      if (!isJson(msg.data)) { // 二进制数据
        data = await praseWsBlob(msg.data)
      } else {
        data = msg.data // json数据
      }

      data = isJsonString(data) ? JSON.parse(data) : data
      if (Object.keys(data).includes('invalidParameter')) {
        return
      }
      // 回复后端的ping
      if (Object.keys(data).includes('ping')) {
        this.toSend({ 'pong': data.ping })
        _isReconnect = false
        _pingTime = data.ping
        return
      }

      this.eventsKeys.filter(k => {
        const re = new RegExp(data.topic)
        return re.test(k)
      }).forEach(key => {
        this.emit(key, fixTime(data))
      })
    }
    // 当连接成功之后, 服务器关闭的情况
    this.ws.onclose = () => {
    }
    this.ws.onerror = () => {
    }
    // 连接成功的事件
    this.ws.onopen = () => {
      _isReconnect = false
      this.runSends()
    }
  }

  toSend (data) {
    // 判断此时此刻有没有连接成功
    const topic = data?.topic
    const cmd = data?.cmd
    if (cmd == 'sub' && topic) {
      _subIds[topic] = data?.id ?? randomString()
    }
    if (cmd == 'unsub' && topic) {
      data.id = _subIds[topic]
      delete _subIds[topic]
    }
    const dataStr = JSON.stringify(data)
    this.ws.send(dataStr)
  }

  // 发送数据的方法
  send (data) {
    const id = data?.id ?? randomString()
    this.sends[id] = () => this.toSend(data)
    this.runSends()
  }

  // 执行send
  runSends () {
    if (this.ws?.readyState == 1) {
      for (const k in this.sends) {
        const task = this.sends[k]
        task()
      }
      this.sends = {}
    }
  }

  // 注册回调
  on (key, callBack) {
    if (!isFunc(callBack)) {
      throw new Error('callBack 非函数')
    }
    this.events[key] = callBack
  }

  // 取消某一个回调函数
  off (key) {
    this.events[key] = null
    delete this.events[key]
  }

  // 触发
  emit (key, data) {
    try {
      if (!key) {
        return
      }
      this.events[key](data)
    } catch (e) {
      console.log('emit err', key, e)
    }
  }

  close () {
    const subIdsKey = Object.keys(_subIds)
    for (let i = 0; i < subIdsKey.length; i++) {
      const key = subIdsKey[i]
      const sendData = {
        'cmd': 'unsub',
        'topic': key,
        'data': {}
      }

      this.off(sendData.topic)
      this.send(sendData)
    }

    Socket.instanceClose()
  }
}
export default MSocket

