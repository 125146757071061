const localTopKey = import.meta.env.VITE_APP_IN_MODULE_TOP_KEY ? JSON.parse(import.meta.env.VITE_APP_IN_MODULE_TOP_KEY.replace(/\'/g, '"')) : []

const help = {
}

const home = {
  '/home': () => import('@/view/home/Home.vue'),
}

const quotes = {
  '/quotes': () => import('@/view/quotes/Quotes.vue'),
}

const trade = {
  '/trade': () => import('@/view/trade/Trade.vue'),
}

const ieo = {
  '/inmodule': () => import('@/view/ieo/Ieo.vue'),
  '/inmodule/subscriptionHistory': () => import('@/view/ieo/SubscriptionHistory.vue'),
  '/inmodule/projectInfo': () => import('@/view/ieo/ProjectInfo.vue'),
}

const user = {
  '/login': () => import('@/view/user/Login.vue'),
  '/register': () => import('@/view/user/Register.vue'),
  '/forgotPassword': () => import('@/view/user/ForgotPassword.vue'),
  '/setNewPassword': () => import('@/view/user/SetNewPassword.vue'),
}

const userCenter = {
  '/rebate': () => import('@/view/rebate/Rebate.vue'),
  '/psersonalCenter/userOverview': () => import('@/view/psersonalCenter/userOverview/UserOverview.vue'),
  '/psersonalCenter/accountSecurity': () => import('@/view/psersonalCenter/accountSecurity/AccountSecurity.vue'),
  '/psersonalCenter/authentication': () => import('@/view/psersonalCenter/authentication/Authentication.vue'),
  '/psersonalCenter/modifyPwd': () => import('@/view/psersonalCenter/accountSecurity/components/ModifyPwd.vue'),
  '/psersonalCenter/modifyFundsPwd': () => import('@/view/psersonalCenter/accountSecurity/components/ModifyFundsPwd.vue'),
  '/psersonalCenter/modifyPhoneOrEmail': () => import('@/view/psersonalCenter/accountSecurity/components/ModifyPhoneOrEmail.vue'),
  '/psersonalCenter/bindGoogle': () => import('@/view/psersonalCenter/accountSecurity/components/BindGoogle.vue'),
  '/psersonalCenter/unBindGoogle': () => import('@/view/psersonalCenter/accountSecurity/components/UnBindGoogle.vue'),
  '/psersonalCenter/switchGoogle': () => import('@/view/psersonalCenter/accountSecurity/components/SwitchGoogle.vue'),
  '/withdrawOut': () => import('@/view/wallet/components/page/WithdrawOut.vue'),
  '/withdrawInner': () => import('@/view/wallet/components/page/WithdrawInner.vue'),
  '/recharge': () => import('@/view/wallet/components/page/Recharge.vue'),

}

const c2c = {
  '/c2c/express': () => import('@/view/c2c/pages/express/Express.vue'),
  '/c2c/optional': () => import('@/view/c2c/pages/optional/Optional.vue'),
  'c2c/order/orderList': () => import('@/view/c2c/pages/OrderList.vue'),
  '/c2c/pribadiAdvert': () => import('@/view/c2c/pages/PribadiAdvert.vue'),
  '/c2c/personalCenter': () => import('@/view/c2c/pages/PersonalCenter/PersonalCenter.vue'),
  '/c2c/infoCenter': () => import('@/view/c2c/pages/InfoCenter/InfoCenter.vue'),
  '/c2c/editAdvert': () => import('@/view/c2c/pages/EditAdvert.vue'),
  '/c2c/addAdvert': () => import('@/view/c2c/pages/EditAdvert.vue'),
  '/c2c/order/fiatOrderDetail': () => import('@/view/c2c/pages/order/FiatOrderDetail.vue'),
}

const futures = {
  '/futures-info': () => import('@/view/futures/pages/FuturesInfo.vue'),
  '/futures': () => import('@/view/futures/Futures.vue'),
}

const allModule = {
  ...help,
  ...home,
  ...ieo,
  ...quotes,
  ...trade,
  ...user,
  ...userCenter,
  ...c2c,
  ...futures,
}

const inModule = (...agr) => {
  agr = agr.flatMap(e => e)
  const allModuleKey = Object.keys(allModule)

  agr.forEach(k => {
    if (allModuleKey.includes(k)) {
      allModule[k]()
    }
  })
}

const idleInModule = (m) => {
  return new Promise((resolve, reject) => {
    requestIdleCallback(async () => {
      await m()
      resolve()
    })
  })
}

const idleInModulLogic = async (key) => {
  let n = 0
  return new Promise(async (resolve, reject) => {
    key.forEach(async (k) => {
      await idleInModule(allModule[k])
      n++
      if (n == key.length) {
        resolve()
      }
    })
  })
}

const idleInModulTop = async () => {
  const tradeKeys = Object.keys(trade)
  const inKeys = [...localTopKey, ...tradeKeys]
  await idleInModulLogic(inKeys)
  return inKeys
}

const idleInModulHigh = async () => {
  const userKeys = Object.keys(user)
  const quotesKeys = Object.keys(quotes)
  const inKeys = [...quotesKeys, ...userKeys]
  await idleInModulLogic(inKeys)
  return inKeys
}

const idleInModuleList = async (opt = {}) => {
  if (!requestIdleCallback) {
    return
  }
  const { delaytime = 500 } = opt
  if (import.meta.env.MODE == 'development') {
    await delay(delaytime)
  }
  await idleInModulTop()
  if (import.meta.env.MODE == 'development') {
    await delay(delaytime)
  }
  await idleInModulHigh()
}

export {
  inModule,
  idleInModule,
  idleInModuleList,
}
