import { createI18n } from 'vue-i18n'
import useConfigStore from '@/store/config.js'

export const createLanguage = async () => {
  const langMessagesMap = {
    'zh': () => import('@/language/zh/index.js'),
    'en': () => import('@/language/en/index.js'),
    'tw': () => import('@/language/tw/index.js'),
  }

  const browserLang = borwserLang()

  const langMap = {
    [LANGCN]: 'zh',
    [LANGEN]: 'en',
    [LANGTW]: 'tw',
  }
  const getCurrentLang = async (key) => (await langMessagesMap[key]()).default

  const getLang = () => {
    const winLocale = getUrlQuery().locale
    const lang = winLocale || localStorage.getItem('locale') || browserLang
    localStorage.locale = lang
    return langMap[lang]
  }

  const lang = getLang()

  const i18n = createI18n({
    globalInjection: false,
    locale: lang,
    messages: {
      [lang]: await getCurrentLang(lang)
    }
  })

  const splice = (...strs) => {
    const locale = i18n.global.locale
    const jmap = {
      'zh': '',
      'en': ' ',
      'tw': ' ',
    }
    const j = jmap[locale]
  }

  const new$t = function (...strs) {
    const locale = i18n.global.locale
    const jmap = {
      'en': ' ',
      'zh': '',
      'tw': '',
    }
    const j = jmap[locale]
    let value = strs.reduce((str, item, i) => `${str}${i != 0 ? j : ''}${i18n.global.t(item) ?? item}`, '')
    if (locale == 'en' && strs.length > 1) {
      value = value.slice(0, 1).toUpperCase() + value.slice(1, value.length).toLowerCase()
    }
    return value
  }

  i18n.new$t = new$t
  new$t.splice = splice
  new$t.p = () => {
    const locale = i18n.global.locale
    const jmap = {
      'zh': '',
      'en': '&nbsp;',
      'tw': '',
    }
    return jmap[locale]
  }

  new$t.curLang = () => {
    const locale = i18n.global.locale
    return i18n.global.messages[locale]
  }

  new$t.replace = i18n.global.t

  new$t.changeLange = async (locale) => {
    const key = langMap[locale]
    const m = await getCurrentLang(key)
    i18n.global.setLocaleMessage(key, m)
    i18n.global.locale = key
  }
  return { $t: new$t, i18n }
}

