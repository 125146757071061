/*
 * @Author: ken yang
 * @Date: 2023-03-16 16:45:15
 * @LastEditTime: 2023-03-16 17:13:09
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/registerServiceWorker.js
 */
/* eslint-disable no-console */

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach(sw => sw.unregister())
  })
}
