/*
 * @Author: ken yang
 * @Date: 2023-06-06 09:21:07
 * @LastEditTime: 2023-06-26 14:06:04
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/api/ieo.js
 */
import axios from '@/utils/request'

const listUrl = '/activity/launchpad/homepage/list'
const list = async (body) => {
  const { data } = await axios.post(listUrl, body, { auth: false })
  return data.data
}

const quickenUrl = '/activity/ieo/quicken'
const quicken = async (body) => {
  const { data } = await axios.post(quickenUrl, body)
  return data.data
}

const infoUrl = '/activity/launchpad/homepage/detail'
const info = async (body) => {
  const { data } = await axios.post(infoUrl, body, { auth: false })
  return data.data
}

const couponUrl = '/activity/ieo/getDefaultIeoCoupons'
const coupon = async (body) => {
  const { data } = await axios.post(couponUrl, body)
  return data.data
}

const subscribeUrl = '/activity/ieo/subscribe'
const subscribe = async (body) => {
  const { data } = await axios.post(subscribeUrl, body)
  return data.data
}

const userIeoInfoUrl = '/activity/ieo/userIeoInfo'
const userIeoInfo = async (body) => {
  const { data } = await axios.post(userIeoInfoUrl, body)
  return data.data
}

const subHistoryUrl = '/activity/launchpad/subscriptionH/history'
const subHistory = async (body) => {
  const { data } = await axios.post(subHistoryUrl, body)
  return data.data
}

const needLoginUrl = []

export default {
  list,
  quicken,
  info,
  coupon,
  subscribe,
  userIeoInfo,
  subHistory,
  needLoginUrl,
}
