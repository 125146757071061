<!--
 * @Author: ken yang
 * @Date: 2023-03-17 13:36:51
 * @LastEditTime: 2023-05-08 10:34:27
 * @LastEditors: ken yang
 * @Description: 活动弹窗
 * @FilePath: /uexch_pc/src/view/common/DiaActivity.vue
-->
<template>
  <el-dialog
    v-model="isActivity"
    width="460px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    append-to-body
    class="activity-award adius-20"
    :show-close="false"
    @closed="toClose"
  >
    <template #default>
      <Column x="e">
        <IconSvg v-show="imgLoad" name="icon-IconClose1" class="font-46 da-close pointer" @click="isActivity = false" />
        <img class="w-100b h-530 radius-20 block" :src="curItem?.imgUrl" alt="no picture" @load="onImgLoad">
        <Row v-show="imgLoad" class="w-410 h-68 m-r-25 da-deail" x="c" y="c">
          <span class="font-42 bold c-white">{{ curItem?.amount }}</span>
          <span class="w-14" />
          <span class="font-42 bold c-white">{{ curItem?.coinName }}</span>
        </Row>
      </Column>
    </template>
  </el-dialog>
</template>
<script setup>
import { activityApi } from '../../api'
const emit = defineEmits()

let isActivity = $ref(false)
let length = $ref(0)
let list = $ref([])
let imgLoad = $ref(false)

const curItem = $computed(() => {
  return list[list.length - length]
})

const checkPop = async () => {
  const data = await activityApi.checkPop()
  length = data.length
  list = data
  isActivity = !!length
  if (!isActivity)emit('end')
}
const forUser = async () => {
  const body = {
    id: curItem.id
  }
  const data = await activityApi.forUser(body)
}

const toClose = async () => {
  imgLoad = false
  if (length === 0) {
    emit('end')
    return
  }
  if (length) {
    await forUser()
  }
  length = length - 1
  setTimeout(() => {
    isActivity = !!length
  }, 500)
}

const onImgLoad = async () => {
  imgLoad = true
}

onBeforeMount(async () => {
  if (localStorage.token) {
    checkPop()
  } else {
    emit('end')
  }
})

</script>
<style lang="scss">
.block{
  display: block;
}
.activity-award{
  background: #f7f9fc00;
  box-shadow: 0px 2px 40px 0px rgba(0,0,0,0.13);
  border-radius: 0px;
  .el-dialog__header{
    padding:0px !important
  }
  .el-dialog__body{
    padding:0px !important
  }
  box-shadow: 0px 0px 0px 0px !important;
}

.da-close{
  position: relative;
  top: -50px;
  right: -50px;
}
.da-deail{
  position: relative;
  bottom: 176px;
}
</style>
