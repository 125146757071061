
// 全局小数位配置
const getDecimalPlaces = (coinName) => {
  const quotesStore = useQuotesStore()
  return quotesStore?.coins.find(obj => obj?.coinName === coinName)?.decimalPlaces
}

// OTC全局配置小数点（C2C）
const c2cDecimalPlaces = (coinName) => {
  const c2cStore = useC2cStore()
  return c2cStore?._coins.find(obj => obj?.coinName === coinName)?.decimalPlaces
}

export {
  getDecimalPlaces,
  c2cDecimalPlaces
}
