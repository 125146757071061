const formatNumber = (x) => {
  if (x >= 1000 && x < 1000000) {
    // return (x / 1000).toFixed(2) + 'K'
    return BigNumber(x).div(1000).decimalPlaces(2, 1) + 'K'
  } else if (x >= 1000000 && x < 1000000000) {
    // return (x / 1000000).toFixed(2) + 'M'
    return BigNumber(x).div(1000000).decimalPlaces(2, 1) + 'M'
  } else if (x >= 1000000000) {
    // return (x / 1000000000).toFixed(2) + 'B'
    return BigNumber(x).div(1000000000).decimalPlaces(2, 1) + 'B'
  } else {
    return x
  }
}

export default formatNumber
