
/*
 * @Author: ken yang
 * @Date: 2022-09-09 11:34:10
 * @LastEditTime: 2023-09-21 14:11:34
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/store/user.js
 */
let logoutRun = true
const useUserStore = defineStore('userStore', {
  state: () => {
    const { token = '', info, feeInfo, isOpenEye } = localStorage

    return {
      token,
      accountInfo: {},
      _futuresAccountInfo: {},
      info: isJsonString(info) ? JSON.parse(info) : {},
      feeInfo: isJsonString(feeInfo) ? JSON.parse(feeInfo) : {},
      futuresFeeInfo: {},
      curFuturesFeeInfo: {}, // 当前用户交易费率
      isOpenEye: isOpenEye == '' ? false : jsonStrToValue(isOpenEye),
      bdUser: null,
      earnWhite: [ // 存币生息的热门币种白名单
        {
          id: 28,
          value: [8, 4]
        },
        {
          id: 29,
          value: [9, 5]
        },
        {
          id: 30,
          value: [10, 5]
        }
      ],
    }
  },
  getters: {
    userConName (state) {
      return state?.info?.nickName || state?.info?.phone || state?.info?.email
    },
    openGoogle  (state) {
      return state?.info?.googleVerifyStatus == 1
    },
    futuresAccountInfo (state) {
      return state._futuresAccountInfo
    }
  },
  actions: {
    async tokenError () {
      if (!this.token) {
        return false
      }
      try {
        const info = await userApi.info({})
        return false
      } catch {
        return true
      }
    },
    async getInfo () {
      const info = await userApi.info({})
      const userInfo = await userApi.userInfo({})

      console.log('userInfo', userInfo.bdUser)
      this.$patch(state => {
        state.info = info
        state.bdUser = userInfo?.bdUser
      })
    },
    async getFee () {
      const feeInfo = await userApi.fee({})
      // console.log('feeInfo', feeInfo)
      const feeLevelDetailInfo = await userApi.feeLevelDetail({})

      const makerFee = this?.info?.userType == 1 ? feeLevelDetailInfo.makerFee : 0
      const takerFee = this?.info?.userType == 1 ? feeLevelDetailInfo.takerFee : 0
      this.$patch(state => {
        state.feeInfo = {
          ...feeLevelDetailInfo,
          makerFee,
          takerFee,
          ...feeInfo,
        }
      })
    },
    async getFuturesFee () {
      const data = await futuresApi.fee({})
      this.$patch(state => {
        state.futuresFeeInfo = {
          ...data,
        }
      })
    },
    async getCurFuturesFee () { // 获取用户的等级信息和费率（takerFee、makerFee ）
      const data = await futuresApi.curFee({})
      this.$patch(state => {
        state.curFuturesFeeInfo = {
          ...data,
        }
      })
    },
    async getAccountInfo (ts = [1, 2, 3]) {
      const accountInfo = {
        ...this.accountInfo,
      }
      for (let i = 0; i < ts.length; i++) {
        const type = ts[i]
        accountInfo[type] = await userApi.accountInfo({ type })
      }

      this.$patch(state => {
        state.accountInfo = accountInfo
      })
    },
    async getFuturesAccountInfo () {
      const data = await futuresApi.contractUsdtlist()

      const futuresStore = useFuturesStore()
      futuresStore.getPositionDeposit()

      this.$patch(state => {
        state._futuresAccountInfo = assignObj(state._futuresAccountInfo, data)
      })
    },

    async logout () {
      try {
        if (logoutRun == false) {
          return
        }

        const quotesStore = useQuotesStore()
        const mapStore = useMapStore()
        const mailStore = useMailStore()
        const futuresStore = useFuturesStore()

        mailStore.list = null
        quotesStore.optionals = []
        quotesStore.contractOptionals = []
        futuresStore.clearTableData()
        this.$patch(state => {
          state.token = ''
          state.accountInfo = {}
          state.info = {}
          state._futuresAccountInfo = {}
        })
        // futuresStore.unsubPosition()
        Socket.instance?.close()
        FuturesSocket.instance?.close()
        mailStore.unsubRecords()
        mapStore.getAreaList()
        quotesStore.getCoins()
        quotesStore.getTickers()
        quotesStore.getSymbolsAll()
        quotesStore.runSocket()
        mapStore.getAreaList()
        mailStore.unsubRecords()

        logoutRun = false
        const HTMLDom = document.querySelector('html')
        HTMLDom?.classList?.add('hide-zendesk')
        setTimeout(() => {
          logoutRun = true
        }, 1200)
        // router.push('/login')
        // 在 JavaScript 中进行页面跳转
        window.location.href = '/login' // 设置为你想要跳转的页面 URL
      } catch (e) {
        console.log('logout err', e)
      }
    },
    async logined () {
      const quotesStore = useQuotesStore()
      const mapStore = useMapStore()
      const mailStore = useMailStore()
      const futuresStore = useFuturesStore()

      await this.getInfo()
      await this.getAccountInfo()
      futuresStore?.getFuturesSettings()
      futuresStore.getfuturesFeeLevelsData()
      this.getFee()
      this.getFuturesFee()
      this.getCurFuturesFee()
      quotesStore.getCoins()
      quotesStore.getTickers()
      quotesStore.getSymbolsAll()
      quotesStore.runSocket()
      quotesStore.getOptionals()
      quotesStore.getContractOptionalList()
      mapStore.getAreaList()
      mailStore.getSetInfo()
      mailStore.getUnreadCount()
      mailStore.getList()
      mailStore.subRecords()
      this.subOrder(false)
    },
    trigger () {
      this.$patch(state => {
        state.isOpenEye = !state.isOpenEye
        localStorage.isOpenEye = state.isOpenEye
      })
    },
    subOrder (isCheck = true) {
      if (isCheck) {
        if (this.tokenError() == true) {
          return
        }
      }

      const ws = Socket.autoConnect()
      const eventName = WS_KEYS.order
      if (ws.hasEvent(eventName)) {
        return
      }

      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': eventName,
        'data': {}
      }

      ws.on(sendData.topic, (data) => {
        eventBus.emit(eventName)
      }, false)
      ws.send(sendData)
    },
  },

  init () {
    eventBus.on(WS_KEYS.order, () => {
      this.getAccountInfo([1])
    })

    this.$subscribe((mutation, state) => {
      localStorage.token = state.token
      localStorage.info = JSON.stringify(state.info)
      localStorage.isOpenEye = state.isOpenEye
    })

    window.addEventListener('storage', (obj) => {
      if (obj.key === 'token') {
        this.$patch(state => {
          state.token = obj.newValue
        })
      }
    })
  },
})

export default useUserStore
