/*
 * @Author: ken yang
 * @Date: 2022-10-08 14:06:46
 * @LastEditTime: 2023-03-17 13:42:07
 * @LastEditors: ken yang
 * @Description: 活动相关接口
 * @FilePath: /uexch_pc/src/api/activity.js
 */
import axios from '@/utils/request'

// 申购列表
const productsUrl = '/account/wealthCoinConfig/page'
const products = async (params) => {
  const { data } = await axios.post(productsUrl, params, { auth: false })
  return data.data
}

const productsAddToken = async (params) => {
  const { data } = await axios.post(productsUrl, params)
  return data.data
}

// 申购历史
const subscribeListUrl = '/account/wealthSubscribe/page'
const subscribeList = async (params) => {
  const { data } = await axios.post(subscribeListUrl, params)
  return data.data
}

// 申购
const subscribeUrl = '/account/wealthSubscribe/subscribe'
const subscribe = async (params) => {
  const { data } = await axios.post(subscribeUrl, params)
  return data.data
}

// 赎回
const redemptionUrl = '/account/wealthSubscribe/ransom'
const redemption = async (params) => {
  const { data } = await axios.post(redemptionUrl, params)
  return data.data
}

// 个人申购历史
const historyUrl = '/account/wealthSubscribe/history/page'
const history = async (params) => {
  const { data } = await axios.post(historyUrl, params)
  return data.data
}

const needLoginUrl = []

export default {
  needLoginUrl,
  products,
  productsAddToken,
  subscribeList,
  subscribe,
  redemption,
  history,
}
