import AppLayout from '@/layout/AppLayout.vue'
const Financial = () => import('@/view/financial/Financial.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/financial',
        component: Financial,
        meta: { key: 'financial' },
      },
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
