<!--
 * @Author: ken yang
 * @Date: 2022-09-02 12:46:05
 * @LastEditTime: 2023-06-19 18:08:30
 * @LastEditors: ken yang
 * @Description: 下载excel
 * @FilePath: /uexch_pc/src/components/buttons/DownloadExcel.vue
-->
<template>
  <el-button type="primary" class="" :loading="loading" @click="onClick">{{ $t('table.downLoadExcel') }}</el-button>
</template>

<script setup>
const route = useRoute()

const emit = defineEmits()
const props = defineProps({
  loading: {
    default: ''
  },
  form: {
    default: {}
  },
  apiFn: {
    required: true
  },
  format: {
    required: true
  },
  name: {},
  dataKey: {
    default: 'records'
  },
})

const onClick = async () => {
  try {
    emit('update:loading', true)
    const body = {
      pageNum: 1,
      pageSize: 10000000,
      ...props.form,
    }
    if (!isEmpty(props.form.time)) {
      body.startTime = props.form.time[0]
      body.endTime = props.form.time[1]
    }
    delete body.time
    const data = await props.apiFn(body)

    const typeAtives = {
      text: (o, k) => o[k],
      img: (o, k) => o[k],
      time: (o, k) => dayjs(o[k]).format('YYYY-MM-DD HH:mm'),
      num: (o, k) => o[k],
    }
    const ls = data[props.dataKey].map(row => {
      let formatData = typeof props.format === 'function' ? props.format() : props.format
      formatData = formatData.filter((obj) => obj.isDownLoad !== false)
      const item = formatData.reduce((o, col) => {
        let value
        if (!isEmpty(col.options)) {
          value = col.options?.find((obj) => obj.value === row[col.prop])?.label
        } else if (!isEmpty(col.value)) {
          value = col.value(row)
        } else if (!isEmpty(col.prop)) {
          value = typeAtives[col?.type ?? 'text'](row, col.prop)
        }
        const v = {
          [col.label]: value
        }
        return { ...o, ...v }
      }, {})
      return item
    })
    downloadExcel(ls, props.name ?? document.title ?? '')
    return data
  } finally {
    emit('update:loading', false)
  }
}
defineExpose({ downLoad: onClick })
</script>

<style lang="scss">

</style>

