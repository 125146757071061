const getCssVar = (dom, v) => getComputedStyle(dom).getPropertyValue(v).trim()

let primary
let success
let warning
let placeholder
let error
let info
let lGrey
let cyan
let fontDefault
let colorOdark
const tradeStylVareMap = {
  'trade-style-0': {
    buy: '--trade-green',
    sell: '--trade-red',
  },
  'trade-style-1': {
    buy: '--trade-red',
    sell: '--trade-green',
  },
}

const $theme = {
  init () {
    const funcKeys = Object.keys(this).filter(k => k != 'init')
    funcKeys.forEach(k => this[k])
  },
  get tradeStyleVar () {
    const keys = Object.keys(tradeStylVareMap)
    const key = keys.find(e => e == localStorage.tradeStyle) ? localStorage.tradeStyle : keys[0]
    return tradeStylVareMap[key]
  },
  get buy () {
    const buy = getCssVar(document.documentElement, this.tradeStyleVar.buy)
    return buy
  },
  get sell () {
    const sell = getCssVar(document.documentElement, this.tradeStyleVar.sell)
    return sell
  },
  get primary () {
    primary = primary || getCssVar(document.documentElement, '--el-color-primary')
    return primary
  },
  get success () {
    success = success || getCssVar(document.documentElement, '--el-color-success')
    return success
  },
  get warning () {
    warning = warning || getCssVar(document.documentElement, '--el-color-warning')
    return warning
  },
  get placeholder () {
    placeholder = placeholder || getCssVar(document.documentElement, '--el-color-placeholder')
    return placeholder
  },
  get error () {
    error = error || getCssVar(document.documentElement, '--el-color-error')
    return error
  },
  get info () {
    info = info || getCssVar(document.documentElement, '--el-color-info')
    return info
  },
  get lGrey () {
    lGrey = lGrey || getCssVar(document.documentElement, '--el-color-lGrey')
    return lGrey
  },
  get cyan () {
    cyan = cyan || getCssVar(document.documentElement, '--el-color-cyan')
    return cyan
  },
  get fontDefault () {
    fontDefault = fontDefault || getCssVar(document.documentElement, '--color-default')
    return fontDefault
  },
  get colorOdark () {
    colorOdark = colorOdark || getCssVar(document.documentElement, '--color-o-dark')
    return colorOdark
  },
}

export default $theme
