<template>
  <div>
    <IpRestrictTip v-if="vieCom('IpRestrictTip')" @end="onEnd('IpRestrictTip')" />
    <DiaNewUserActivity v-if="vieCom('DiaNewUserActivity')" @end="onEnd('DiaNewUserActivity')" />
    <DiaActivity v-if="vieCom('DiaActivity')" @end="onEnd('DiaActivity')" />
    <Announcement v-if="vieCom('Announcement')" @end="onEnd('Announcement')" />
  </div>
</template>
<script setup>
const route = useRoute()
import { onBeforeMount, reactive } from 'vue'

const exIpRestrictipPaths = ['/login', '/setNewPassword', '/registerUpLand', '/user/register']
const isIpRestrictip = $computed(() => {
  return !exIpRestrictipPaths.includes(route.path)
})

const diaObject = reactive({
  ip_restrict: false, // ip限制
  new_user_airdrop_popup: false, // 拉新活动
  reward_popup: false, // 活动奖励弹窗
  announcement_popup: false, // 公告
})

const queue = $ref([
  {
    key: 'IpRestrictTip',
    isView: false,
    viewPaths: {
      inOrEx: 'ex',
      paths: ['/login', '/setNewPassword', '/registerUpLand', '/user/register', '/help/content']
    }
  },
  {
    key: 'DiaNewUserActivity',
    isView: false,
    viewPaths: {
      inOrEx: 'in',
      paths: ['home']
    }
  },
  {
    key: 'DiaActivity',
    isView: false,
    viewPaths: {
      inOrEx: 'in',
      paths: ['']
    }
  },
  {
    key: 'Announcement',
    isView: false,
    viewPaths: {
      inOrEx: 'in',
      paths: ['']
    }
  },
])

const vieCom = (key) => {
  return queue.find((obj) => obj.key === key)?.isView
}

const getStart = () => {
  for (let index = 0; index < queue.length; index++) {
    const obj = queue[index]
    if (obj.viewPaths.inOrEx === 'in' && obj.viewPaths.paths.includes(route.path)) {
      obj.isView = true
      break
    } else if (obj.viewPaths.inOrEx === 'ex' && !obj.viewPaths.paths.includes(route.path)) {
      obj.isView = true
      break
    } else {
      console.log(obj?.key, obj[index - 1]?.key)
    }
  }
}

// const getStart = () => {
//   if (exIpRestrictipPaths) {
//     diaObject.ip_restrict = true
//     return
//   }

//   if (route.path === '/home') {
//     diaObject.new_user_airdrop_popup = true
//     return
//   }

//   diaObject.announcement_popup = true
//   return
// }

const onEnd = (key) => {
  const index = queue.findIndex((obj) => obj.key === key)
  if (index + 1 === queue.length) return
  const nextIndex = index + 1
  queue[nextIndex].isView = true
  // if (key === 'ip_restrict') {
  //   diaObject.new_user_airdrop_popup = true
  // }
  // if (key === 'new_user_airdrop_popup') {
  //   diaObject.reward_popup = true
  // }
  // if (key === 'reward_popup') {
  //   diaObject.announcement_popup = true
  // }
}

onBeforeMount(async () => {
  getStart()
})

</script>
<style lang="scss" scoped>
</style>
