<template>
  <img class="QrCode" :src="fileQRcode" :value="fileQRcode">
</template>

<script setup>
import QRcode from 'jr-qrcode'

const props = defineProps({
  url: {},
  size: {
    default: 100
  },
})
const fileQRcode = $computed(() => QRcode.getQrBase64(props.url))
const size = $computed(() => props.size + 'px')

onBeforeMount(() => {
})
</script>

<style lang="scss">
.QrCode {
  width: v-bind(size);
}
</style>
