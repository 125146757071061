<template>
  <FlexWidget
    :id="formId"
    class="DynamicForm"
    :class="[wrap ? 'f-wrap' : '']"
    :x="x"
    :y="'c'"
  >
    <Row
      v-for="(item) in fields"
      :key="'box'+item.key"
      :class="formItemClass"
      :x="x"
      :y="y"
    >
      <Row v-if="!isEmpty(item.label)" class="p-r-6 c-info h-inherit text-nowrap" y="c">
        <span class=" text-nowrap" :style="{'font-size': `${fontSize}`, 'margin-left': spaceX + 'px' }">{{ item.label }}</span>
      </Row>
      <div
        v-if="item.type == 'slot'"
        :key="item.key"
        :style="fieldStyle(item)"
        :class="appendFieldClass(item)"
      >
        <slot :name="item.name" />
      </div>
      <span
        v-if="item.type == 'text'"
        :key="item.key"
        :test-key="`${item.key}-${item.type}`"
        :name="item.name"
        :style="fieldStyle(item)"
        :class="appendFieldClass(item)"
      >{{ item.value }}</span>
      <el-input
        v-if="item.type == 'input'"
        :key="item.key"
        :test-key="`${item.key}-${item.type}`"
        :style="fieldStyle(item)"
        :class="appendFieldClass(item)"
        :modelValue="item.value"
        :placeholder="item.placeholder"
        clearable
        @input="(e) => onChange(item.key, e)"
        @keyup.enter="submit"
      />
      <el-input
        v-if="item.type == 'inputNumber'"
        :key="item.key"
        type="number"
        :test-key="`${item.key}-${item.type}`"
        :style="fieldStyle(item)"
        :class="appendFieldClass(item)"
        :modelValue="item.value"
        :placeholder="item.placeholder"
        clearable
        @input="(e) => onChange(item.key, e)"
        @keyup.enter="submit"
      />
      <el-select
        v-if="item.type == 'select'"
        :key="item.key"
        :test-key="`${item.key}-${item.type}`"
        :filterable="item.filterable ?? true"
        :style="fieldStyle(item)"
        :class="appendFieldClass(item)"
        :modelValue="item.value"
        :placeholder="item.placeholder"
        placement="bottom"
        :popper-class="`${theme} DynamicForm-select-popper`"
        @change="(e) => onChange(item.key, e)"
        @keyup.enter="submit"
      >
        <el-option
          v-for="option in item.options"
          :key="option.value"
          class="h-28 lh-28 label-sm font-w-400"
          :test-key="`${item.key}-${item.type}-${option.value}`"
          :label="option.label"
          :value="option.value"
          :disabled="option.disabled||false"
        />
      </el-select>
      <el-radio-group
        v-if="item.type == 'radio'"
        :key="item.key"
        :test-key="`${item.key}-${item.type}`"
        :modelValue="item.value"
        :style="fieldStyle(item)"
        :class="appendFieldClass(item)"
        :placeholder="item.placeholder"
        @change="(e) => onChange(item.key, e)"
      >
        <el-radio-button
          v-for="option in item.options"
          :key="option.value"
          :test-key="`${item.key}-${item.type}-${option.value}`"
          :label="option.value"
          :value="option.value"
        >{{ option.label }}
        </el-radio-button>
      </el-radio-group>
      <el-switch
        v-if="item.type == 'switch'"
        :key="item.key"
        :test-key="`${item.key}-${item.type}`"
        :modelValue="item.value"
        :style="fieldStyle(item)"
        :class="appendFieldClass(item)"
        :placeholder="item.placeholder"
        @change="(e) => onChange(item.key, e)"
      />
      <el-checkbox
        v-if="item.type == 'checkbox'"
        :key="item.key"
        :test-key="`${item.key}-${item.type}`"
        :modelValue="item.value"
        :style="fieldStyle(item)"
        :class="appendFieldClass(item)"
        :placeholder="item.placeholder"
        @change="(e) => onChange(item.key, e)"
      />
      <el-checkbox-group
        v-if="item.type == 'checkboxGroup'"
        :key="item.key"
        :test-key="`${item.key}-${item.type}`"
        :modelValue="item.value"
        :style="fieldStyle(item)"
        :class="appendFieldClass(item)"
        :placeholder="item.placeholder"
        @change="(e) => onChange(item.key, e)"
      >
        <el-checkbox
          v-for="option in item.options"
          :key="option.value"
          :test-key="`${item.key}-${item.type}-${option.value}`"
          :label="option.label"
          :value="option.value"
        />
      </el-checkbox-group>
      <el-date-picker
        v-if="item.type == 'daterange'"
        :id="`${formId}-${item.key}`"
        :key="item.key"
        v-model="item.value"
        :popper-class="`${theme}`"
        class="pointer"
        :test-key="`${item.key}-${item.type}`"
        type="daterange"
        :shortcuts="getShortcuts(item)"
        range-separator="-"
        :start-placeholder="item.startPlaceholder ?? $t('table.startTime')"
        :end-placeholder="item.endPlaceholder ?? $t('table.endTime')"
        :default-time="defaultTime"
        :disabled-date="item.disabledDate"
        :prefix-icon="''"
        :style="fieldStyle(item)"
        :class="appendFieldClass(item)"
        :placeholder="item.placeholder"
        :clearable="false"
        @calendar-change="(e) => daterangeChange(item.key, e)"
      />
    </Row>
    <div :style="intervalStyle()" class="df-aciton" :class="formItemClass">
      <el-button
        v-if="hasSubmit"
        class="p-lr-12 radius-2 search-box"
        test-key="search"
        :style="fieldStyle()"
        :class="appendFieldClass()"
        type="primary"
        @click="submit"
      >{{ $t('table.search') }}</el-button>
      <el-button
        v-if="hasReset"
        class="p-lr-12 radius-2 reset-box c-primary border-c-primary-50"
        test-key="reset"
        :style="fieldStyle()"
        :class="appendFieldClass()"
        @click="reset"
      >{{ $t('table.remake') }}</el-button>
    </div>
    <slot name="end" />
  </FlexWidget>
</template>

<script setup>
import { Row, Column } from '@/layout/index.js'
const emit = defineEmits()
const props = defineProps({
  x: { default: 's' },
  y: { default: 's' },
  flex: { default: 'Row' },
  formItemClass: {
    default: '',
  },
  theme: { default: 'light' },
  wrap: { default: true },
  fields: { default: [] },
  spaceX: { default: 20 },
  spaceY: { default: 0 },
  interval: { default: 20 },
  labelClass: {
    default: 'p-r-8 label c-info h-100b',
  },
  hasSubmit: { default: true },
  hasReset: { default: false },
  dateFormat: { default: 'YYYY-MM-DD' },
  fieldHeight: {
    default: 34,
  },
  fontSize: {
    default: 12,
  }
})

const flexMap = {
  Row: Row,
  Column: Column,
}

const formId = `dynamicForm-${randomString()}`
let rowFields = $ref(null)

// const dateValue = reactive({})
const defaultTime = [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]

const FlexWidget = $computed(() => flexMap[props.flex])
const fieldHeight = $computed(() => props.fieldHeight + 'px')
const fontSize = $computed(() => props.fontSize + 'px')

const fieldStyle = (item = {}) => {
  const dMap = {
    'Row': 'right',
    'Column': 'bottom',
  }
  return { [`margin-${dMap[props.flex]}`]: props.spaceX + 'px', 'margin-bottom': props.spaceY + 'px' }
}

const intervalStyle = (item = {}) => {
  return { ...fieldStyle, 'margin-left': (props.interval - props.spaceX) + 'px' }
}

const appendFieldClass = (item = {}) => {
  let c = ''
  if (['input', 'inputNumber', 'select'].includes(item.type) && !/w-\d/.test(item.class)) {
    c = 'w-150'
  } else if (item.type == 'date' && !/w-\d/.test(item.class)) {
    c = 'w-180'
  } else if (item.type == 'daterange' && !/w-\d/.test(item.class)) {
    c = 'w-330'
  }

  return `${c} ${item.class}`
}

const onChange = (key, value) => {
  emit('change', key, value)
}

const onPickerChange = (key, e) => {
  if (isEmpty(e)) {
    onChange(key, [])
  } else {
    const startTime = dayjs(e[0]).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
    const endTime = dayjs(e[1]).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 59)

    onChange(key, [startTime.valueOf(), endTime.valueOf()])
  }
}

const daterangeChange = (key, value) => {
  if (isEmpty(value) || !(value[0]) || !(value[1])) {
    return value
  }
  onPickerChange(key, value)
  return value
}

const submit = () => {
  emit('submit')
}

const getShortcuts = (item) => [
  {
    text: $t('form.datePickerCycle.day3'),
    value: () => {
      const timeRange = setTimeRange('day', 2)
      onChange(item.key, timeRange)
      return timeRange
    },
  },
  {
    text: $t('form.datePickerCycle.day7'),
    value: () => {
      const timeRange = setTimeRange('day', 6)
      onChange(item.key, timeRange)
      return timeRange
    },
  },
  {
    text: $t('form.datePickerCycle.day30'),
    value: () => {
      const timeRange = setTimeRange('month', 1)
      onChange(item.key, timeRange)
      return timeRange
    },
  },
  {
    text: $t('form.datePickerCycle.month3'),
    value: () => {
      const timeRange = setTimeRange('month', 3)
      onChange(item.key, timeRange)
      return timeRange
    },
  },
  {
    text: $t('form.datePickerCycle.month6'),
    value: () => {
      const timeRange = setTimeRange('month', 6)
      onChange(item.key, timeRange)
      return timeRange
    },
  },
  {
    text: $t('form.clear'),
    value: () => {
      const timeRange = []
      onChange(item.key, timeRange)
      return timeRange
    },
  },
  // {
  //   text: $t('form.datePickerCycle.month12'),
  //   value: () => {
  //     const timeRange = setTimeRange('month', 12)
  //     onChange(item.key, timeRange)
  //     return timeRange
  //   },
  // },
]

const reset = () => {
  const fields = props.fields
  fields.forEach(e => {
    const target = rowFields.find((obj) => {
      return obj.key == e.key
    })
    let v
    if (['radio'].includes(e.type)) {
      v = target.value ?? null
    } else if (!isEmpty(e.value)) {
      v = target.value ?? ''
    } else if (isString(e.value)) {
      v = target.value ?? ''
    } else if (isArray(e.value)) {
      v = target.value ?? []
    } else if (isBoolean(e.value)) {
      v = target.value ?? false
    } else {
      v = target?.value ?? null
    }

    emit('change', e.key, v)
  })
  emit('reset')
}

onBeforeMount(() => {
  rowFields = [...props.fields]
})

</script>

<style lang="scss">
@use "sass:math";
@use "sass:map";
@import '@/sass/var/index.scss';

.light{
  .DynamicForm{
    .el-radio-button{
      --el-button-text-color: #{$color-info};
      --el-radio-button-checked-text-color: #{$font-color};
      --el-radio-button-checked-bg-color: #{$color-lGrey};
      --el-radio-button-checked-border-color: #{$color-placeholder};
      --el-border-color: #{$color-placeholder};

    }
  }
}

.dark{
  .DynamicForm{
    .el-radio-button{
      --el-border: 1px solid #2D344E;
      --el-button-text-color: var(--el-color-info);
      --el-radio-button-checked-text-color: #fff;
      --el-radio-button-checked-bg-color: #1D2336;
      --el-radio-button-checked-border-color: #2D344E;
      --el-border-color: #2D344E;
    }
    .el-radio-button__inner:hover{
      color: #fff;
    }
    .search-box:hover{
      background-color: rgba($color-primary,0.9) !important;
      --el-button-hover-border-color: #{rgba($color-primary,0.9)};
    }
    .el-input,.el-input__wrapper{
      --el-input-hover-border-color: #2D344E;
      --el-input-border-color: #2D344E;
      background: transparent !important;
    }
  }
}

.DynamicForm {
  .el-input{
    --el-font-size-base: 14px;
  }
  .el-range-input{
    cursor: pointer !important;
  }
  .reset-box{
    --el-button-border-color: var(--el-color-primary);
    --el-button-hover-border-color: var(--el-color-primary);
  }

  .el-date-editor{
    .el-range__icon{
      display: none;
    }
  }
  .el-input__wrapper{
    width: 100%;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .label {
    height: v-bind(fieldHeight);
  }
  .el-input__wrapper, .el-radio-button__inner{
    --el-border-radius-base: 2px;
  }
  .el-input,.el-input__wrapper{
    --el-input-height: v-bind(fieldHeight);
    height: var(--el-input-height);
    --el-font-size-base: v-bind(fontSize);
  }
  .el-radio-button{
  }
  .el-radio-group{
    flex-wrap: inherit;
  }
  .el-radio-button__inner{
    height: v-bind(fieldHeight);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: v-bind(fontSize);
  }
  .el-input__inner{
    font-size: v-bind(fontSize) !important;
  }
  .el-button{
    height: v-bind(fieldHeight) !important;
    font-size: v-bind(fontSize) !important;
  }
  .el-date-editor--datetimerange{
  }

  ::-webkit-input-placeholder {
  font-size: v-bind(fontSize) !important;
  }
}
.df-aciton{
  display: flex;
  // --el-color-primary-light-9: #{$color-lGrey} !important;
}
</style>

