// 向下取整数倍
const floorInt = (int, num) => {
  try {
    if (window.isNaN(int) || window.isNaN(num)) {
      throw new Error('int or num 是非数字')
    }
    if (int === 0) {
      throw new Error('int不能是0')
    }
    return BigNumber(int).times(Math.floor(BigNumber(num).div(int))).toNumber()
  } catch (error) {
    // console.error('floorInt', error)
    return NaN
  }
}

// 向上取整数倍
const ceilingInt = (int, num) => {
  try {
    if (window.isNaN(int) || window.isNaN(num)) {
      throw new Error('int or num 是非数字')
    }
    if (int === 0) {
      throw new Error('int不能是0')
    }
    return BigNumber(int).times(Math.ceil(BigNumber(num).div(int))).toNumber()
  } catch (error) {
    // console.error('floorInt', error)
    return NaN
  }
}

const roundup = (int, num) => {
  try {
    if (window.isNaN(int) || window.isNaN(num)) {
      throw new Error('int or num 是非数字')
    }
    const multiplier = Math.pow(10, int)
    const roundedNumber = BigNumber(Math.ceil(BigNumber(num).times(multiplier))).div(multiplier)
    return roundedNumber
  } catch (error) {
    // console.error('roundup', error)
    return NaN
  }
}

export {
  floorInt,
  ceilingInt,
  roundup
}
