/*
 * @Author: ken yang
 * @Date: 2022-09-03 11:18:27
 * @LastEditTime: 2023-02-28 16:57:01
 * @LastEditors: ken yang
 * @Description: c2c 路由文件
 * @FilePath: /uexch_pc/src/view/ieo/index.js
 */
import AppLayout from '@/layout/AppLayout.vue'

// import Quotes from '@/view/quotes/Quotes.vue'
const Ieo = () => import('@/view/ieo/Ieo.vue')
const SubscriptionHistory = () => import('@/view/ieo/SubscriptionHistory.vue')
const ProjectInfo = () => import('@/view/ieo/ProjectInfo.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/launchpad',
        component: Ieo,
        meta: { key: 'ieo' },
      },
      {
        path: '/launchpad/subscriptionHistory',
        component: SubscriptionHistory,
      },
      {
        path: '/launchpad/projectInfo',
        props: route => ({ id: route.query.id }),
        component: ProjectInfo,
      },
      {
        path: '/ieo',
        component: Ieo,
        meta: { key: 'ieo' },
      },
      {
        path: '/ieo/subscriptionHistory',
        component: SubscriptionHistory,
      },
      {
        path: '/ieo/projectInfo',
        props: route => ({ id: route.query.id }),
        component: ProjectInfo,
      },
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
