import { throttle } from 'lodash-es'

const throttleTime = 0

const intervals = [
  {
    name: '1m',
    value: 'one_minute',
    interval: '1',
  },
  {
    name: '5m',
    value: 'five_minute',
    interval: '5',
  },
  {
    name: '15m',
    value: 'fifteen_minute',
    interval: '15',
  },
  {
    name: '30m',
    value: 'thirty_minute',
    interval: '30',
  },
  {
    name: '1H',
    value: 'one_hour',
    interval: '60',
  },
  {
    name: '4H',
    value: 'four_hour',
    interval: '60',
  },
  {
    name: '1D',
    value: 'one_day',
    interval: '60',
  },
  {
    name: '1W',
    value: 'one_week',
    interval: '1W',
  },
  {
    name: '1M',
    value: 'one_month',
    interval: '1W',
  },
]

const filterData = (cb, symbol, interval) => {
  return ({ data }) => {
    if (symbol == data?.coinMarket) {
      const klines = data.klines
      cb(klines[interval])
    }
  }
}

const chainOff = (re) => {
  const ws = FuturesSocket.autoConnect()

  for (let i = 0; i < ws?.eventsKeys?.length; i++) {
    const eKey = ws?.eventsKeys[i]
    if (re.test(eKey)) {
      ws.off(eKey)
    }
  }
}
const useFuturesStore = defineStore('futuresStore', {
  state: () => {
    // 方向类型，1：双向持仓；2：单向持仓
    const positionModeMap = {
      'hedge': 1,
      'oneWay': 2,
    }

    return {
      // theme: localStorage.theme ?? 'light', // light dark
      intervalName: localStorage.futuresKLineIntervalName || intervals[1].name,
      symbol: localStorage.fsymbol ?? '',
      kLineCbs: {},
      v1TakerFee: '', // vip1的take费率
      positionTabValue: 'open', // open是开仓 close是平仓
      positionList: [],
      openOrders: [],
      openOrdersEntrustStop: [],
      openOrdersType: 1,
      usdtTradeStatus: '', // 用户usdt永续合约交易状态,0-失效,1-有效
      positionMode: MERGE, // 仓位模式（合仓/分仓）MERGE-1-合仓，SPLIT-2-分仓
      uniAssets: {}, // 单向持仓的
      sells: [],
      buys: [],
      symbolInfoDatas: {}, // 当前币种 获取标记价格/指数价格（symbol 栏用）
      curCoinMarket: {}, // 当前币种信息 包含面值
      unit: localStorage.f_unit || SYMUNITKEY, // USDTUNITKEY PCSUNITKEY
      maxNum: 1,
      minNum: 100,
      leverMarks: {},
      multiple: 5, // 杠杆倍数
      positionType: CROSS, // 仓位保证金模式（全仓/逐仓）CROSS-0-全仓，SHARED-1-逐仓
      renderStateEd: 1,
      // 下单确认
      orderConfirmationLimit: jsonStrToValue(localStorage.orderConfirmationLimit) ?? true,
      orderConfirmationMarket: jsonStrToValue(localStorage.orderConfirmationMarket) ?? true,
      orderConfirmationHighLevelLimit: jsonStrToValue(localStorage.orderConfirmationHighLevelLimit) ?? true,
      orderConfirmationFlashClose: jsonStrToValue(localStorage.orderConfirmationFlashClose) ?? true,
      takeCloseConfirmation: jsonStrToValue(localStorage.takeCloseConfirmation) ?? true,
      errorEntrustNo: []
    }
  },

  getters: {
    positionModeOptions: (state) => [
      { label: $t('futures.positionModeMap.hedge'), value: state.positionModeMap.hedge },
      { label: $t('futures.positionModeMap.oneWay'), value: state.positionModeMap.oneWay },
    ],
    themeOptions: () => [
      { label: 'dark', value: 'dark' },
      { label: 'light', value: 'light' },
    ],
    statusOptions: () => [
      { label: $t('futures.statusMap.0'), value: 0 },
      { label: $t('futures.statusMap.1'), value: 1 },
      { label: $t('futures.statusMap.2'), value: 2 },
      { label: $t('futures.statusMap.3'), value: 3 },
      { label: $t('futures.statusMap.4'), value: 4 },
    ],
    unitOptions: (state) => {
      return {
        [USDTUNITKEY]: 'USDT',
        [CUSDTUNITKEY]: 'USDT',
        [PCSUNITKEY]: $t('futures.pcs'),
        [SYMUNITKEY]: state?.baseCoinName,
      }
    },
    positionModeOption: () => [
      {
        label: '合仓',
        key: MERGE,
      },
      {
        label: '分仓',
        key: SPLIT,
      },
    ],
    APoptions: () => [
      {
        label: 'USDT',
        key: USDTUNITKEY
      },
      {
        label: $t('futures.pcs'),
        key: PCSUNITKEY
      }
    ],
    positionTypeOptions: () => [
      { label: $t(`futures.positionTypeMap.${CROSS}`), value: CROSS },
      { label: $t(`futures.positionTypeMap.${SHARED}`), value: SHARED },
    ],
    orderFormOptions: () => [
      { label: 'Post Only', value: 3 },
      { label: 'IOC', value: 4 },
      { label: 'FOK', value: 5 },
    ],
    priceTypeOptions: () => [
      { label: $t('futures.limit'), value: 1 },
      { label: $t('futures.market'), value: 2 },
      { label: $t('futures.limittaker'), value: 3 },
      { label: $t('futures.markettaker'), value: 4 },
      { label: $t('futures.forcedPositionReduction'), value: 5 },
      { label: $t('futures.liquidation'), value: 6 },
      { label: $t('futures.adl'), value: 7 },
      { label: $t('futures.highLevelLimit'), value: 8 },
    ],
    priceTypeOptions2: () => [
      { label: $t('futures.limit'), value: 1 },
      { label: $t('futures.market'), value: 2 },
      { label: $t('futures.forcedPositionReduction'), value: 5 },
      { label: $t('futures.liquidation'), value: 6 },
      { label: $t('futures.adl'), value: 7 },
      { label: $t('futures.highLevelLimit'), value: 8 },
    ],
    closingTypeOptions: () => [
      { label: $t('futures.openTypeMap.1'), value: 1 },
      { label: $t('futures.openTypeMap.2'), value: 2 },
      { label: $t('futures.openTypeMap.3'), value: 3 },
      { label: $t('futures.openTypeMap.4'), value: 4 },
    ],
    closingTypeOptions2: () => [
      { label: $t('futures.openTypeMap2.1'), value: 1 },
      { label: $t('futures.openTypeMap2.2'), value: 2 },
    ],
    transactionTypeOptions: () => [
      { label: $t('futures.transactionTypeMap.1'), value: 1 },
      { label: $t('futures.transactionTypeMap.2'), value: 2 },
      { label: $t('futures.transactionTypeMap.3'), value: 3 },
      { label: $t('futures.transactionTypeMap.4'), value: 4 },
      { label: $t('futures.transactionTypeMap.5'), value: 5 },
      { label: $t('futures.transactionTypeMap.6'), value: 6 },
      { label: $t('futures.transactionTypeMap.7'), value: 7 },
      { label: $t('futures.transactionTypeMap.8'), value: 8 },
      { label: $t('futures.transactionTypeMap.9'), value: 9 },
      { label: $t('futures.transactionTypeMap.10'), value: 10 },
      { label: $t('futures.transactionTypeMap.11'), value: 11 },
    ],
    triggerTypeOptions: () => [
      { label: $t('futures.mark'), value: 1 },
      { label: $t('futures.last'), value: 2 },
      { label: $t('futures.limit2'), value: 3 },
      { label: $t('futures.market2'), value: 4 },
    ],
    triggerDirectionOptions: () => [
      { label: $t('futures.takeProfitPrice2'), value: 1 },
      { label: $t('futures.stopLossPrice2'), value: 2 },
    ],
    mathMap: () => ({
      '1-1': '≥',
      '1-2': '≤',
      '2-2': '≥',
      '2-1': '≤',
    }),
    referenceOptions: () => [
      {
        label: $t('futures.mark'),
        labelFull: $t('futures.markPrice'),
        key: 1
      },
      {
        label: $t('futures.last'),
        labelFull: $t('futures.lastPrice'),
        key: 2
      }
    ],
    intervals: () => intervals,
    interval: (state) => intervals.find(e => e.name == state.intervalName),
    maxLevelInfo: (state) => { // 当前杠杆最大可开裆位
      if (isEmpty(state?.curCoinMarket?.stepGear)) return {}
      const stepGear = state?.curCoinMarket?.stepGear
      const _multiple = state.multiple
      let curItem = null
      if (+_multiple <= stepGear[stepGear.length - 1].lever) {
        curItem = stepGear[stepGear.length - 1]
        return curItem
      }
      const index = stepGear.findIndex((item, index) => {
        return +_multiple > +stepGear[index + 1]?.lever && +_multiple <= +item?.lever
      })
      curItem = stepGear[index]
      return curItem
    },
    symbolConfig: (state) => { // 当前币信息
      const quotesStore = useQuotesStore()
      return quotesStore?.contractSymbolsAll.find(e => e.coinMarket == state.symbol.replace('/', '')) ?? {}
    },
    pricePrecision: (state) => { // 价格精度
      return state?.symbolConfig?.pricePrecision ?? 0
    },
    amountPrecision: (state) => { // 数量精度
      return state.symbolConfig?.amountPrecision ?? 0
    },
    baseCoinName: (state) => { // 合约基础单位 eg:BTC
      return state.curCoinMarket?.baseCoinName
    }
  },
  actions: {
    // async setFuturesSettings (v) {
    //   const data = await futuresApi.setUserSettings({ directionType: v })
    //   this.$patch(state => {
    //     state.positionMode = +v
    //   })
    // },
    async getFuturesSettings () {
      const data = await futuresApi.getUserSettings({})
      this.$patch(state => {
        if (data.pointType)state.positionMode = data.pointType
      })
    },
    // async getFuturesAccountInfo () {
    //   const data = await futuresApi.contractUsdtlist()
    //   this.$patch(state => {
    //     state._futuresAccountInfo = data ?? {}
    //   })
    // },
    // -- KLine --
    async subKLine (symbol, intervalName, cb) {
      const ws = FuturesSocket.autoConnect()
      this.kLineCbs[symbol] = cb
      chainOff(new RegExp(`${WS_CONTRACT_KEYS.ticker}@KLine`))
      const key = `${WS_CONTRACT_KEYS.ticker}@KLine-${symbol}`
      const intervalInfo = intervals.find(e => e.name == intervalName)
      // close
      const ecb = throttle(filterData(cb, symbol, intervalInfo.value), throttleTime)

      ws.on(key, ecb, false)
    },
    async switchKLine (symbol, intervalName, cb) {
      const re = new RegExp(`${WS_CONTRACT_KEYS.ticker}@KLine`)
      this.kLineCbs = {}
      this.subKLine(symbol, intervalName, cb)
    },
    async unsubKLineEvent (symbol) {
      const ws = FuturesSocket.autoConnect()
      const key = `${WS_CONTRACT_KEYS.ticker}@KLine-${symbol}`
      ws.off(key)
    },
    async switchInterval (symbol, intervalName) {
      this.intervalName = intervalName
      this.unsubKLineEvent(symbol, false)
      this.subKLine(symbol, intervalName, this.kLineCbs[symbol])
    },
    // -- Price --
    async subPrice (symbol, cb) {
      const ws = FuturesSocket.autoConnect()
      const key = `${WS_CONTRACT_KEYS.ticker}@price-${symbol}`
      const ecb = throttle(({ data }) => {
        if (symbol == data?.coinMarket) {
          cb(data)
        }
      }, throttleTime)

      ws.on(key, ecb, false)
    },
    async unsubPrice (symbol) {
      const ws = FuturesSocket.autoConnect()
      const key = `${WS_CONTRACT_KEYS.ticker}@price-${symbol}`
      ws.off(key)
    },
    async switchPrice (symbol, cb) {
      const re = new RegExp(`${WS_CONTRACT_KEYS.ticker}@price`)
      chainOff(re)
      this.subPrice(symbol, cb)
    },

    // -- TradeHistory（盘口：最新成交） --
    async subTradeHistory (symbol, cb) {
      const ws = FuturesSocket.autoConnect()
      const key = `${WS_CONTRACT_KEYS.ticker}@tradeHistory-${symbol}`
      const ecb = throttle(({ data }) => {
        if (symbol == data?.coinMarket) {
          cb(data.trade)
        }
      }, throttleTime)
      ws.on(key, ecb, false)
    },
    async unsubTradeHistory (symbol) {
      const ws = FuturesSocket.autoConnect()
      const key = `${WS_CONTRACT_KEYS.ticker}@tradeHistory-${symbol}`
      ws.off(key)
    },
    async switchTradeHistory (symbol, cb) {
      const re = new RegExp(`${WS_CONTRACT_KEYS.ticker}@tradeHistory`)
      chainOff(re)
      this.subTradeHistory(symbol, cb)
    },
    // -- Depth --
    async subDepth (symbol, cb) {
      const ws = FuturesSocket.autoConnect()
      const eventName = `alpha-market-depth-contract-${symbol.toLowerCase()}-trade`
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': eventName,
        'data': {}
      }
      const ecb = throttle(({ data }) => cb(data), throttleTime)

      ws.on(sendData.topic, ecb, false)
      ws.send(sendData)
    },
    unsubDepth (symbol) {
      const ws = FuturesSocket.autoConnect()
      const eventName = `alpha-market-depth-${symbol.replace(/\//, '-').toLowerCase()}-trade`
      if (!ws.hasEvent(eventName)) {
        return
      }
      const sendData = {
        'cmd': 'unsub',
        'topic': eventName,
        'data': {}
      }
      ws.off(sendData.topic)
      ws.send(sendData)
    },
    async switchDepth (symbol, cb) {
      const ws = FuturesSocket.autoConnect()
      const re = /^alpha-market-depth-(.+)-trade$/
      const eventName = `alpha-market-depth-${symbol.replace(/\//, '-').toLowerCase()}-trade`
      const previousEventName = ws?.eventsKeys.find(e => re.test(e))
      if (previousEventName == eventName) {
        return
      }
      if (!isEmpty(previousEventName)) {
        const sendData = {
          'cmd': 'unsub',
          'topic': previousEventName,
          'data': {}
        }
        ws.off(sendData.topic)
        ws.send(sendData)
      }
      chainOff(re)
      this.subDepth(symbol, cb)
    },
    // -- DepthPaint --
    async subDepthPaint (symbol, cb) {
      const ws = FuturesSocket.autoConnect()
      const eventName = `alpha-market-depth-contract-${symbol.toLowerCase()}-paint`
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': eventName,
        'data': {}
      }
      const ecb = throttle(({ data }) => cb(data), throttleTime)

      ws.on(sendData.topic, ecb, false)
      ws.send(sendData)
    },
    unsubDepthPaint (symbol) {
      const ws = FuturesSocket.autoConnect()
      const eventName = `alpha-market-depth-contract-${symbol.toLowerCase()}-paint`
      if (!ws.hasEvent(eventName)) {
        return
      }
      const sendData = {
        'cmd': 'unsub',
        'topic': eventName,
        'data': {}
      }

      ws.off(sendData.topic)
      ws.send(sendData)
    },
    async switchDepthPaint (symbol, cb) {
      try {
        const ws = FuturesSocket.autoConnect()
        const re = /^alpha-market-depth-(.+)-paint$/
        const eventName = `alpha-market-depth-contract-${symbol.toLowerCase()}-paint`
        const previousEventName = ws?.eventsKeys.find(e => re.test(e))
        if (previousEventName == eventName) {
          return
        }
        if (!isEmpty(previousEventName)) {
          const sendData = {
            'cmd': 'unsub',
            'topic': previousEventName,
            'data': {}
          }
          ws.off(sendData.topic)
          ws.send(sendData)
        }
        chainOff(re)
        this.subDepthPaint(symbol, cb)
      } catch (e) {
        console.log('switchDepthPaint', e)
      }
    },
    // --MarketPrice--
    async subMarketPrice (symbol, cb) {
      const ws = FuturesSocket.autoConnect()
      const eventName = `usdt-marked-price-${symbol.replace(/\//, '').toLowerCase()}`
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': eventName,
        'data': {}
      }
      const ecb = throttle(({ data }) => cb(data), throttleTime)

      ws.on(sendData.topic, ecb, false)
      ws.send(sendData)
    },
    unsubMarketPrice (symbol) {
      const ws = FuturesSocket.autoConnect()
      const eventName = `usdt-marked-price-${symbol.replace(/\//, '').toLowerCase()}`
      if (!ws.hasEvent(eventName)) return
      const sendData = {
        'cmd': 'unsub',
        'topic': eventName,
        'data': {}
      }
      ws.off(sendData.topic)
      ws.send(sendData)
    },
    switchMarketPrice (symbol, cb) {
      try {
        const ws = FuturesSocket.autoConnect()
        const re = /^usdt-marked-price-(.+)$/
        const eventName = `usdt-marked-price-${symbol.replace(/\//, '').toLowerCase()}`
        const previousEventName = ws?.eventsKeys.find(e => re.test(e))

        if (previousEventName == eventName) {
          return
        }
        if (!isEmpty(previousEventName)) {
          const sendData = {
            'cmd': 'unsub',
            'topic': previousEventName,
            'data': {}
          }
          ws.off(sendData.topic)
          ws.send(sendData)
        }
        chainOff(re)
        this.subMarketPrice(symbol, cb)
      } catch (e) {
        console.log('switchMarketPrice', e)
      }
    },
    async getPosition () {
      const body = { }
      const data = await futuresApi.contractPosition(body)
      this.$patch(state => {
        state.positionList = assignObj(state.positionList ?? [], data ?? [])
      })
    },
    // --position--
    unsubPosition () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.position
      const sendData = {
        'cmd': 'unsub',
        'topic': topic,
        'data': {}
      }
      ws.off(topic)
      ws.send(sendData)
    },
    subPosition () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.position
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': topic,
        'data': {}
      }

      ws.on(sendData.topic, ({ data = {}}) => {
        if (isEmpty(data)) {
          return
        }
        if (this.errorEntrustNo.find(e => e.id == data.id)) return

        this.$patch(state => {
          const index = state.positionList.findIndex(e => e.id == data.id)
          if (data.status == 0 && index != -1) {
            this.errorEntrustNo.push(data)
            state.positionList.splice(index, 1)
            eventBus.emit('position-change')
          } else if (data.status == 1 && index != -1) {
            if (state.positionList[index].flatAmount != data.flatAmount) {
              eventBus.emit('position-override', data)
              eventBus.emit('position-change')
            }
            state.positionList[index] = data
          } else if (data.status == 1 && index == -1) {
            state.positionList.push(data)
            eventBus.emit('position-change')
          }
          if (data.positionType !== 1) { // 全仓的情况下给资产赋值推送过来的新数据
            userStore._futuresAccountInfo.accountEquity = data?.accountEquity
            userStore._futuresAccountInfo.availableDeposit = data?.availableDeposit
            userStore._futuresAccountInfo.positionDeposit = data?.positionDepositTotal
            userStore._futuresAccountInfo.entrustedDepositBalance = data?.entrustedDepositBalance
            userStore._futuresAccountInfo.totalBalance = data?.totalBalance
          }
        })
      })
      ws.send(sendData)
    },

    // --orders(委托相关)--
    unsubOpenOrders  () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.openOrders
      const sendData = {
        'cmd': 'unsub',
        'topic': topic,
        'data': {}
      }
      ws.off(topic)
      ws.send(sendData)
    },
    subOpenOrders  () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.openOrders
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': topic,
        'data': {}
      }

      ws.on(sendData.topic, ({ data = {}}) => {
        if (isEmpty(data)) {
          return
        }
        eventBus.emit(WS_CONTRACT_KEYS.openOrders, data)
      })
      ws.send(sendData)
    },
    // --stop(当前委托：止盈止损)--
    unsubStop  () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.stop
      const sendData = {
        'cmd': 'unsub',
        'topic': topic,
        'data': {}
      }
      ws.off(topic)
      ws.send(sendData)
    },
    subStop  () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.stop
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': topic,
        'data': {}
      }

      ws.on(sendData.topic, ({ data = {}}) => {
        if (isEmpty(data)) {
          return
        }
        eventBus.emit(WS_CONTRACT_KEYS.stop, data)
      })
      ws.send(sendData)
    },
    async getContractStatus () {
      try {
        const d = await futuresApi.contractStatus()
        this.$patch(state => {
          state.usdtTradeStatus = d?.usdtTradeStatus
        })
      } catch (error) {
        console.log('获取合约状态失败', error)
      } finally {
      }
    },
    async getPositionDeposit () {
      const body = {
        coinMarket: this.symbol
      }
      const data = await futuresApi.positionDeposit(body)
      this.$patch(state => {
        state.uniAssets = data.data
      })
    },
    async getLeverInfo () {
      let res = null
      try {
        res = await futuresApi.leverInfo({ coinMarket: this.symbol })
        this.$patch(state => {
          state.maxNum = res.maxValue
          state.minNum = res.minValue
          state.multiple = res.defaultValue
          state.positionType = res.positionType
          const keyLen = NP.divide(100, 5)
          const valueLen = NP.divide(state.maxNum, 5)
          const leverInfo = reactive({ 1: '1X' })
          for (let index = 1; index <= 5; index++) {
            leverInfo[NP.times(index, keyLen)] = NP.times(index, valueLen) + 'X'
          }
          state.leverMarks = leverInfo
        })
      } catch (error) {
        console.error('getLeverInfo', error)
      }
    },
    async getfuturesFeeLevelsData  () {
      try {
        const d = await futuresApi.feeList({})
        this.$patch(state => {
          state.v1TakerFee = d[0]?.takerFee
        })
      } finally {
      }
    },
    clearTableData () {
      this.$patch(state => {
        state.positionList = []
        state.openOrders = []
        state.openOrdersEntrustStop = []
      })
    },
  },
  init () {
    this.getContractStatus()
    this.$subscribe((mutation, state) => {
      localStorage.futuresKLineIntervalName = state.intervalName
      localStorage.fsymbol = state.symbol
      localStorage.f_unit = state.unit
      localStorage.orderConfirmationLimit = state.orderConfirmationLimit
      localStorage.orderConfirmationMarket = state.orderConfirmationMarket
      localStorage.orderConfirmationHighLevelLimit = state.orderConfirmationHighLevelLimit
      localStorage.orderConfirmationFlashClose = state.orderConfirmationFlashClose
      localStorage.takeCloseConfirmation = state.takeCloseConfirmation
    })
  },
})

export default useFuturesStore
