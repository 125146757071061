<template>
  <span class="AmountNum num-f">
    {{ $f.amountNumFormat(slots.default()[0].children) }}
  </span>
</template>

<script setup>

const props = defineProps({

})
const slots = useSlots()

onBeforeMount(() => {
})
</script>

<style lang="scss">
.AmountNum {
  font-family: 'Roboto';
}
</style>
