import axios from '@/utils/request'

const listUrl = '/activity/voteCoin/homePage'
const list = async (body = {}) => {
  const { data } = await axios.post(listUrl, body)
  return data.data
}

const endListUrl = '/activity/vote/endPage'
const endList = async (body = {}) => {
  const { data } = await axios.post(endListUrl, body)
  return data.data
}

const historyUrl = '/activity/userVote/history'
const history = async (body = {}) => {
  const { data } = await axios.post(historyUrl, body)
  return data.data
}

const projectProfileUrl = '/activity/voteCoin/detail'
const projectProfile = async (body = {}) => {
  const { data } = await axios.post(projectProfileUrl, body)
  return data.data
}

const voteUrl = '/activity/vote'
const vote = async (body = {}) => {
  const { data } = await axios.post(voteUrl, body)
  return data.data
}

const needLoginUrl = []

export default {
  needLoginUrl,
  list,
  endList,
  vote,
  projectProfile,
  history,
}
