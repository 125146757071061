const st = (s, index, f = '/') => {
  if (isEmpty(s)) {
    return ''
  }
  if (!s.split(f).length) {
    return ''
  }
  return s.split(f)[index]
}
const getFixedNum = (num) => {
  try {
    if (isEmpty(num)) {
      return ''
    }
    const f = $f.st(num.toString(), 1, '.') ?? ''
    return f?.length ?? 0
  } catch {
    return ''
  }
}
const conver = (v) => {
  if (isString(v)) {
    return v
  }
  const abs = Math.abs(+v)
  const str = abs.toString()
  let f
  const isz = /e\+/.test(str)
  const isf = /e\-/.test(str)
  const isNegative = /^\-/.test(v)
  if (isz) {
    f = '+'
  } else if (isf) {
    f = '-'
  } else {
    return v
  }
  const [n, zeroNum] = str.split('e' + f)
  const nFixedNum = getFixedNum(n) || 0
  const zerosLen = f == '+' ? (zeroNum - nFixedNum) : zeroNum - 1
  const zeros = Array.from({ length: zerosLen }, () => 0).join('')
  let newValue
  if (isz) {
    newValue = `${n.toString().replace('.', '')}${zeros}`
  } else if (isf) {
    newValue = `0.${zeros}${n.toString().replace('.', '')}`
  }
  return `${isNegative ? '-' : ''}${newValue}`
}

// 大于或者等于0的数 包括小数点
const matchPositiveNum = (origin) => {
  return origin.replace(/^\./, '').replace(/[^0-9\.]/g, '').replace(/^-/, '').replace(/(\..*)\./, '$1').replace(/^0+/, '0')
}

// 数字包括小数
const matchNum = (origin) => {
  return origin.replace(/^\./, '').replace(/[^0-9\.\-]/g, '').replace(/(\..*)\./, '$1').replace(/^0+/, '0')
}

let ooldNum = ''
const inputNum = (vs) => {
  const v = vs.toString()

  if (/.*\..*\./.test(v)) return ooldNum
  if (v[0] == '.') {
    return `0${v}`
  }
  if ((!/^[0-9]{1,}[.]*([0-9])*$/.test(v) && v != '') || v.slice(v.length - 2) == '..') {
    return v.substring(0, v.length - 1)
  }
  let value = v
  if (/^0\d$/.test(value) || /^0\d+/.test(value)) {
    value = value.toString().slice(1)
  }
  value.replace(/-/, '')
  ooldNum = value
  return value
}

const toFixed = (v, lan) => {
  v = conver(v)
  try {
    const [a, b] = String(v).split('.')
    const fixedNum = getFixedNum(v)
    if (a == 0 && !b && lan) {
      return `${a}.${(Array.from({ length: lan }, () => 0)).join('')}`
    } else if ((lan == 0 && a == 0) || (a == 0 && !b)) {
      return 0
    } else if (lan == 0) {
      return a
    } else if (lan == 0 || (a == 0 && b == undefined)) {
      return `${a}.${(Array.from({ length: lan }, () => 0)).join('')}`
    } else if (isEmpty(v)) {
      return null
    } else if (isEmpty(b)) {
      return `${a}.${(Array.from({ length: lan }, () => 0)).join('')}`
    } else if (lan > fixedNum) {
      const val = String(v).slice(0, a.length + 1 + lan)
      return `${val}${(Array.from({ length: lan - fixedNum }, () => 0)).join('')}`
    } else {
      return String(v).slice(0, a.length + 1 + lan)
    }
  } catch (e) {
    return ''
  }
}

const numFormat = (testv = '', b) => {
  const text = conver(testv).toString().replace('-', '')
  const isNegative = /^-/.test(testv.toString())

  if (isNaN(text)) {
    return text
  }

  const integerStr = text?.toString()?.split('.')[0] ?? ''
  const fractional = text?.toString()?.split('.')[1] ?? ''
  const fractionalLen = fractional.length ?? 0

  const integerArr = []
  for (const i in integerStr) {
    const index = integerStr.length - 1 - i
    let item = integerStr[index]
    if (integerStr.length - 1 != i && integerArr.length != 0 && (integerArr.length + 1) % 3 == 0) {
      item = `,${item}`
    }
    integerArr.push(item)
  }
  const integer = integerArr.reverse().join('')
  const desimal = ((isZero(fractional) && fractionalLen) || Number(fractional)) ? `.${fractional}` : ''
  return `${isNegative ? '-' : ''}${integer}${desimal}`
}

const amountNumFormat = (text, b) => {
  const num = Number(text)
  if (isNaN(text)) {
    return text
  }
  const actions = [
    { key: '', value: 0, trigger: 0 },
    { key: 'M', value: 6, trigger: 6 },
    { key: 'B', value: 9, trigger: 9 },
  ]
  const nNum = $f.toFixed(Math.abs(num, 0), 0).length - 1
  const action = actions.reduce((v, e) => {
    if (nNum >= e.trigger) {
      return e
    } else {
      return v
    }
  })
  const nValue = (num / Math.pow(10, action.value))
  const fractionalLen = text?.toString()?.split('.')[1]?.length ?? 0
  const endStr = nNum >= action.trigger ? action.key : ''

  return `${numFormat(toFixed(nValue, fractionalLen))}${endStr}`
}

const toFixedLimit = (v, lan) => {
  try {
    return getFixedNum(v) > lan ? toFixed(v, lan) : v
  } catch (e) {
    return 0
  }
}
const numberLimit = (v, l, title) => {
  let val = Number(v)
  const limit = Number(l)
  if (val > limit) {
    val = limit
    if (title) {
      ElMessage.warning(title)
    }
  }
  return val
}

const toStartUpperCase = str => {
  try {
    return str[0].toUpperCase() + str.slice(1)
  } catch (e) {
    return ''
  }
}

const coverText = (val = '', show, length) => {
  const str = val.toString()
  length = length ?? str.length
  return show ? str : Array.from({ length: str.length }, () => '*').join('')
}

const priceClass = (v, cls = 'c-info') => {
  let s
  if (isEmpty(v)) {
    return ''
  } else if (v == 0) {
    s = cls
  } else if (v >= 0) {
    s = 'c-buy'
  } else {
    s = 'c-sell'
  }
  return s
}

const priceTabClass = v => {
  let s
  if (v == 0) {
    s = 'info'
  } else if (v >= 0) {
    s = 'buy'
  } else {
    s = 'sell'
  }
  return `tab-${s}`
}

// 涨跌幅
const pnFill = (v = 0, s) => {
  let d = ''
  let value = v

  if (!isEmpty(s) && /undefined/.test(s)) {
    return '-'
  } else if (v > 0) {
    d = '+'
  } else if (value < 0 || /-/.test(v)) {
    d = '-'
    value = String(value).replace('-', '')
  }
  return !isEmpty(value) ? `${d}${toFixed(value, 2)}` : 'C'
}
const hidePhone = (v) => {
  if (!$v.re.phone.test(v)) {
    // throw new Error('非电话')
    return ''
  }
  return `${v.substr(0, 3)}****${v.substr(-4)}`
}
const hideEmail = (v) => {
  if (!$v.re.email.test(v)) {
    // throw new Error('非电话')
    return ''
  }
  return `${v.substr(0, 2)}****@***.com`
}

const getAssetsFile = (url) => {
  return new URL(url, import.meta.url).href
}

const keepDot = (str, length) => {
  if (!length) return str
  if (!str) return str
  str = str.toString()
  const lan = +length
  const [front, end] = str.split('.')
  if (end && end.length > lan) {
    const newEnd = end.slice(0, lan)
    return [front, newEnd].join('.')
  } else {
    return str
  }
}

export default {
  conver,
  numFormat,
  inputNum,
  amountNumFormat,
  priceClass,
  priceTabClass,
  toFixed,
  getFixedNum,
  toFixedLimit,
  numberLimit,
  toStartUpperCase,
  st,
  coverText,
  pnFill,
  hidePhone,
  hideEmail,
  getAssetsFile,
  keepDot,
  matchPositiveNum,
  matchNum,
}
