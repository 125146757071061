<!--
 * @Author: ken yang
 * @Date: 2023-05-08 17:24:27
 * @LastEditTime: 2023-06-22 11:43:17
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/layout/flex/Row.vue
-->
<template>
  <Flex direction="row" :justifyContent="x" :alignItems="y" :class="stretch ? 'flex-row-stretch':''">
    <slot />
  </Flex>
</template>

<script setup>
const props = defineProps({
  x: {
    default: 's',
  },
  y: {
    default: 'c',
  },
  stretch: {
    default: false,
  }
})

onBeforeMount(() => {})
</script>

<style lang="scss">
</style>
