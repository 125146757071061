const isJson = (data) => {
  try {
    JSON.parse(data)
    return true // 数据可以被解析为JSON
  } catch (e) {
    return false // 数据不是有效的JSON
  }
}

export default isJson
