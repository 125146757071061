<!--
 * @Author: ken yang
 * @Date: 2023-04-24 15:09:01
 * @LastEditTime: 2023-04-26 10:26:16
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/components/table/TableAction.vue
-->
<template>
  <Row class="w-100b" x="s">
    <span v-for="(item,index) in actions" :key="index">
      <el-button
        v-if="item.type==='delete'"
        link
        type="danger"
        class="h-26 label-sm"
        :disabled="item.isdisabled"
        @click="detail.show = true"
      >{{ item.label }}</el-button>
      <el-button
        v-else
        :type="item.type"
        class="h-26 label-sm"
        link
        :disabled="item.isdisabled"
        @click="item.onClick"
      >{{ item.label }}</el-button>
    </span>

    <el-dialog
      v-model="detail.show"
      :width="detail.width"
      :destroy-on-close="true"
      append-to-body
      :title="$t('form.delete')"
    >
      <template #default>
        <Row>
          {{ delObj?.popConfirm?.title??$t('alert.title.del') }}
        </Row>
      </template>
      <template #footer>
        <span>
          <el-button class="m-r-10" @click="detail.show=false">{{ $t('alert.cancel') }}</el-button>
          <el-button type="primary" :loading="loading" @click="submit">
            {{ $t('alert.confirm') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </Row>
</template>
<script setup>
import { ElMessage } from 'element-plus'

const detail = reactive({
  show: false,
  width: 350
})
let loading = $ref(false)
const props = defineProps({
  actions: {
    require: true
  },
  getData: {}
})

const delObj = $computed(() => {
  return props.actions.find((obj) => obj.type === 'delete') || []
})

const submit = async () => {
  detail.show = true
  loading = true
  try {
    const res = await delObj.requestApi(delObj.body)
    if (res) {
      ElMessage.success($t('alert.delSuccess'))
    }
  } catch (error) {
  } finally {
    loading = false
    detail.show = false
    props.getData()
  }
}

</script>
<style lang="scss">
</style>
