<!--
 * @Author: ken yang
 * @Date: 2023-02-14 13:44:04
 * @LastEditTime: 2023-08-28 11:20:10
 * @LastEditors: ken yang
 * @Description: 图片公告弹窗
 * @FilePath: /uexch_pc/src/components/common/Announcement.vue
-->
<template>
  <el-dialog
    class="dia-clear"
    modal-class="dialog-m-center"
    :modelValue="detail.show"
    width="500px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    append-to-body
    :show-close="false"
    @close="close"
  >
    <template #default>
      <div class="clear-close" @click="close" />
      <img :class="['w-500 h-360',pictrues[0]?.url?'pointer':'']" :src="pictrues[0]?.picture" @click="toOpen">
    </template>
  </el-dialog>
</template>
<script setup>
const emit = defineEmits()
const userStore = useUserStore()
const route = useRoute()
const detail = reactive({
  show: false
})
let pictrues = $ref([])
const noTriggerRoutePath = ['/registerUpLand']

const AnnouncementsWindows = async () => {
  const data = await userApi.AnnouncementsWindows({})
  if (+localStorage.announcementId === data?.picture[0]?.id) return
  pictrues = data.picture
  detail.show = !!pictrues[0]?.picture
}

const toOpen = () => {
  if (pictrues[0]?.url) {
    detail.show = false
    window.open(pictrues[0]?.url)
  }
}

const close = () => {
  detail.show = false
  localStorage.announcementId = pictrues[0].id
}

onMounted(() => {
  if (!userStore.token) {
    return emit('end')
  }
  setTimeout(() => {
    if (!noTriggerRoutePath.includes(route.path)) {
      AnnouncementsWindows()
    }
  }, 200)
})
</script>
<style lang="scss">
.dia-clear{
  background-color:rgba(0, 0, 0, 0);
  box-shadow:0 0 0 !important;
  .el-dialog__header{
    height: 0px;
    padding: 0px;
    margin: 0px;
  }
  .el-dialog__body{
    padding: 0px !important;
  }
  .close{

  }
  .clear-close{
    transition: all 0.3s;
    position: absolute;
    top: 14px;
    right: 14px;
    font-size: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-image: url("image/X-1.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    &:hover{
      background-image: url("image/X-1-hover.png");
    }
  }
}

</style>
