/*
 * @Author: ken yang
 * @Date: 2022-09-09 11:34:10
 * @LastEditTime: 2023-04-13 17:29:56
 * @LastEditors: ken yang
 * @Description: 钱包store
 * @FilePath: /uexch_pc/src/store/wallet.js
 */
import walletRoutes from '@/view/wallet/index.js'
let menuRoutes = walletRoutes.routes[0]?.children[0]?.children
menuRoutes = menuRoutes.filter((obj) => obj.isMenu)
menuRoutes = menuRoutes.map((obj) => {
  return {
    path: obj.path,
    name: obj.name,
    title: obj.title,
  }
})
const useWalletStore = defineStore('walletStore', {
  state: () => {
    return {
      menuMaps: menuRoutes
    }
  },
  getters: {
  },
  actions: {
  },
  init () {
  }
})

export default useWalletStore
