/*
 * @Author: ken yang
 * @Date: 2023-06-05 14:32:59
 * @LastEditTime: 2023-06-27 11:48:03
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/view/c2c/index.js
 */
/*
 * @Author: ken yang
 * @Date: 2023-06-05 14:32:59
 * @LastEditTime: 2023-06-05 14:59:38
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/view/c2c/index.js
 */
import AppLayout from '@/layout/AppLayout.vue'
const C2c = () => import('@/view/c2c/C2c.vue')
const Express = () => import('@/view/c2c/pages/express/Express.vue')
const Optional = () => import('@/view/c2c/pages/optional/Optional.vue')
const OrderList = () => import('@/view/c2c/pages/OrderList.vue')
const PersonalCenter = () => import('@/view/c2c/pages/PersonalCenter/PersonalCenter.vue')
const InfoCenter = () => import('@/view/c2c/pages/InfoCenter/InfoCenter.vue')
const PribadiAdvert = () => import('@/view/c2c/pages/PribadiAdvert.vue')
const EditAdvert = () => import('@/view/c2c/pages/EditAdvert.vue')
const FiatOrderDetail = () => import('@/view/c2c/pages/order/FiatOrderDetail.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/c2c',
        component: C2c,
        meta: { key: 'c2c' },
        redirect: '/c2c/express',
        children: [
          {
            path: 'express',
            component: Express,
          },
          {
            path: 'optional',
            component: Optional,
          },
          {
            path: 'order/fiatOrderDetail',
            component: FiatOrderDetail,
            props: route => {
              const orderId = route.query.orderId
              const payType = route.query.payType
              return { orderId, payType }
            },
          },
          {
            path: 'editAdvert',
            component: EditAdvert,
            props: route => {
              const recordId = route.query.recordId
              return { recordId }
            },
            meta: {
              keepAlive: false
            },
          },
          {
            path: 'addAdvert',
            component: EditAdvert,
            props: route => {
              return { }
            },
            meta: {
              keepAlive: false
            },
          },
          {
            path: 'order/orderList',
            component: OrderList,
          },
          {
            path: 'pribadiAdvert',
            component: PribadiAdvert,
          },
          {
            path: 'personalCenter',
            component: PersonalCenter,
          },
          {
            path: 'infoCenter',
            component: InfoCenter,
          },
        ]
      },

      // {
      //   path: '/c2c/tradeOrders',
      //   component: TradeOrders,
      // },
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
