<template>
  <Row class="bi-check-box" y="c">
    <Row @click="onChange">
      <IconSvg v-if="modelValue" name="icon-IconCheck_Input" :class="`${theme}-icon-box icon-box`" />
      <div v-else :class="`${theme}-box box`" />
      <slot name="default" />
    </Row>
  </Row>
</template>
<script setup>
const emit = defineEmits()
const configStore = useConfigStore()
const props = defineProps({
  modelValue: {},
  isContainDarkModel: {
    default: false
  }
})

const theme = $computed(() => {
  return props.isContainDarkModel ? configStore.theme : 'light'
})

const onChange = () => {
  emit('change', !props.modelValue)
}
</script>
<style lang="scss" scoped>
@import "@/sass/var/index.scss";
.bi-check-box{
  cursor: pointer;
  .box{
    width: 14px;
    height: 14px;
    border-radius: 2px;
    box-sizing: border-box;
  }
  .icon-box{
    width: 14px;
    height: 14px;
  }
  .light-box{
    border:1px solid $color-placeholder;
  }
  .light-icon-box{
    color: $color-primary;
  }

  .dark-box{
    border:1px solid #2D344E;
  }
  .dark-icon-box{
    color: $color-primary-dark;
  }
}
</style>
